import React, { Fragment } from "react";
import {
  AutocompleteInput,
  ReferenceInput,
  Title,
  translate
} from "react-admin";

import PropTypes from "prop-types";
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import { change, Field, Form, reduxForm } from "redux-form";
import { connect } from "react-redux";
import CustomAutocompleteInput from "../CustomAutocompleteInput";
import CardActions from "@material-ui/core/CardActions";
import {
  checkPreRegistration,
  getCompetition,
  getCompetitionSquadStands,
  prepareOrder,
  updateStand,
  validateRegistrationCode
} from "../../utils/restUtils";
import { setError } from "../../reducer/errorActions";
import {
  renderCheckbox,
  renderSelectField,
  renderTextField
} from "../inputs/renderMaterialFields";
import {
  isAdmin,
  isClubAdmin,
  isPortalAdmin,
  isShooter
} from "../../utils/utils";
import countries from "./country-codes-case-lower.json";
import ErrorMessage from "../ErrorMessage";
import { austrianStates } from "../../utils/states";

const styles = {
  fullWidth: {
    width: "100%"
  },
  signupBtn: {
    color: "#ffffff",
    backgroundColor: "#7C757B",
    marginTop: "10px",
    width: "100%"
  },
  cancelBtn: {
    color: "#ffffff",
    backgroundColor: "#7C757B",
    marginTop: "10px",
    width: "100%"
  }
};

const RenderOptions = props => {
  return props.values
    ? props.values.map(item => {
        return <option value={item.id}>{item.name}</option>;
      })
    : null;
};

const RenderCountryOptions = () => {
  return countries
    ? countries.map(item => {
        return <option value={item.countryCode}>{item.countryName}</option>;
      })
    : null;
};

const renderCustomLabel = label => {
  const labelStyle = {
    color: "gray",
    fontSize: "12px"
  };
  return <label style={labelStyle}>{label} </label>;
};

const renderCustomAutocompleteInput = ({
  input,
  label,
  type,
  options,
  source,
  translate,
  validate,
  defaultValue,
  isMulti,
  meta: { touched, error, warning, invalid }
}) => {
  return (
    <>
      <CustomAutocompleteInput
        validate={validate}
        {...input}
        isMulti={isMulti}
        invalid={invalid}
        placeholder={label}
        source={source}
        type={type}
        options={options}
        translate={translate}
        defaultValue={defaultValue}
        label={renderCustomLabel(label)}
        touched={touched}
        error={error}
        warning={warning}
        isClearable={false}
      />
      {touched &&
        ((error && (
          <Typography>
            <span style={{ color: "red", fontSize: "12px" }}>
              {translate(error)}
            </span>
          </Typography>
        )) ||
          (warning && <p>{warning}</p>))}
    </>
  );
};
const renderUserAutocompleteInput = ({
  input,
  label,
  type,
  options,
  source,
  translate,
  validate,
  defaultValue,
  isMulti,
  meta: { touched, error, warning, invalid }
}) => {
  return (
    <>
      <CustomAutocompleteInput
        validate={validate}
        {...input}
        isMulti={isMulti}
        invalid={invalid}
        placeholder={label}
        source={source}
        type={type}
        options={options}
        translate={translate}
        defaultValue={defaultValue}
        label={renderCustomLabel(label)}
        touched={touched}
        error={error}
        warning={warning}
        isClearable={true}
      />
      {touched &&
        ((error && (
          <Typography>
            <span style={{ color: "red", fontSize: "12px" }}>
              {translate(error)}
            </span>
          </Typography>
        )) ||
          (warning && <p>{warning}</p>))}
    </>
  );
};

const required = (value, allValues, props) =>
  value !== undefined && value !== "" && value !== null
    ? undefined
    : props.translate("FIELD_IS_REQUIRED");
const email = (value, allValues, props) =>
  value !== undefined &&
  value !== "" &&
  value !== null &&
  value.match(
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
  )
    ? undefined
    : props.translate("FIELD_IS_REQUIRED");
const agb = (value, allValues, props) =>
  value !== undefined && value !== "" && value
    ? undefined
    : props.translate("FIELD_IS_REQUIRED");

const squadWaitingListOnlyAvailable = squad =>
  squad.squadSize <= squad.subscriptionSize &&
  squad.squadSize + squad.maxWaitingListSize > squad.subscriptionSize;

const getSquadsWithWaitingListOnlyAvailable = squads => {
  let squadsWithWaitingListOnlyAvailable = [];
  squads.forEach(squad => {
    if (squadWaitingListOnlyAvailable(squad))
      squadsWithWaitingListOnlyAvailable.push(squad);
  });
  return squadsWithWaitingListOnlyAvailable;
};

function StateOptions(props) {
  return props.states.map(item => <option value={item.id}>{item.name}</option>);
}

class CompetitionSignupWizard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      competition: props.competition,
      squad: props.squad,
      squadStand: null,
      squadStands: null,
      requireRegistrationCode: null,
      registrationCode: null,
      order: null,
      orderItem: {},
      multipleWaitingList: false,
      countdown: moment(),
      seconds: 0
    };

    this.startTimer = this.startTimer.bind(this);
    this.countdown = this.countdown.bind(this);
  }

  componentDidMount() {
    let squad = this.props.squad;

    if (this.state.competition && this.state.competition.event) {
      squad = this.state.competition.squads[0];
      this.setState({ squad: squad, squadStand: null });
    }

    if (squad) {
      this.requireRegistrationCode();
    }

    this.startTimer();
  }

  startTimer() {
    this.timer = setInterval(this.countdown, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  countdown() {
    if (this.state.countdown) {
      const diff = this.state.countdown.diff(moment(), "seconds");
      this.setState({
        seconds: diff > 0 ? diff : 0
      });
    }
  }

  requireRegistrationCode() {
    checkPreRegistration(
      this.state.competition.id,
      0,
      this.state.squad.id
    ).then(
      data => {
        const require =
          (data.data ||
            this.state.competition.nationalCompetition ||
            this.state.squad.requireCode) &&
          !isAdmin(this.props.permissions) &&
          !(
            isClubAdmin(this.props.permissions) &&
            "" + this.state.competition.clubId === this.props.userData.clubId
          );
        this.setState({ requireRegistrationCode: require });
        if (!require) {
          this.prepareOrder();
        }
      },
      e => this.props.dispatch(setError(e))
    );
  }

  prepareOrderForm() {
    if (this.state.squad)
      this.props.dispatch(
        change(this.props.form, "squadName", this.state.squad.name)
      );
    if (this.state.squadStand)
      this.props.dispatch(
        change(this.props.form, "squadStandName", this.state.squadStand.name)
      );
    this.props.dispatch(
      change(this.props.form, "firstName", this.props.identity.userFirstName)
    );
    this.props.dispatch(
      change(this.props.form, "lastName", this.props.identity.userLastName)
    );
    this.props.dispatch(
      change(this.props.form, "email", this.props.identity.userEmail)
    );
    this.props.dispatch(
      change(this.props.form, "clubId", this.props.identity.clubId)
    );

    if (this.props.competition && this.props.competition.state)
      this.props.dispatch(
        change(this.props.form, "state", this.props.identity.userState)
      );

    if (
      this.props.identity.divisionId &&
      this.props.competition.division &&
      this.props.competition.division.findIndex(
        division => division.id === this.props.identity.divisionId
      ) !== -1
    )
      this.props.dispatch(
        change(this.props.form, "divisionId", this.props.identity.divisionId)
      );
    else this.props.dispatch(change(this.props.form, "divisionId", null));

    if (
      this.props.identity.factorId &&
      this.props.competition.factor &&
      this.props.competition.factor.findIndex(
        factor => factor.id === this.props.identity.factorId
      ) !== -1
    )
      this.props.dispatch(
        change(this.props.form, "factorId", this.props.identity.factorId)
      );
    else this.props.dispatch(change(this.props.form, "factorId", null));

    if (
      this.props.identity.competitionClassId &&
      this.props.competition.competitionClass &&
      this.props.competition.competitionClass.findIndex(
        competitionClass =>
          competitionClass.id === this.props.identity.competitionClassId
      ) !== -1
    )
      this.props.dispatch(
        change(
          this.props.form,
          "competitionClassId",
          this.props.identity.competitionClassId
        )
      );
    else
      this.props.dispatch(change(this.props.form, "competitionClassId", null));

    if (this.state.orderItem) {
      if (this.state.orderItem.divisionId)
        this.props.dispatch(
          change(this.props.form, "divisionId", this.state.orderItem.divisionId)
        );
      if (this.state.orderItem.factorId)
        this.props.dispatch(
          change(this.props.form, "factorId", this.state.orderItem.factorId)
        );
      if (this.state.orderItem.competitionClassId)
        this.props.dispatch(
          change(
            this.props.form,
            "competitionClassId",
            this.state.orderItem.competitionClassId
          )
        );
      if (this.state.orderItem.squadStand) {
        this.props.dispatch(
          change(
            this.props.form,
            "squadStand",
            this.state.orderItem.squadStand.id
          )
        );
      }
    }
  }

  prepareOrder() {
    if (this.state.competition.event) {
      this.setState(
        {
          loading: false,
          order: {
            status: "RESERVED",
            orderItems: [
              {
                competitionId: this.state.competition.id,
                squadId: this.state.squad.id,
                reservationCode: this.state.registrationCode
              }
            ]
          },
          orderItem: {},
          countdown: null
        },
        () => this.prepareOrderForm()
      );
    } else {
      let data = {
        status: "PREPARE",
        orderItems: [
          {
            competitionId: this.state.competition.id,
            squadId: this.state.squad.id,
            reservationCode: this.state.registrationCode
          }
        ]
      };

      this.setState({ loading: true }, () =>
        prepareOrder(data)
          .then(o => {
            this.setState(
              {
                squad: o.orderItems[0].squad,
                squadStand: o.orderItems[0].squadStand,
                order: o,
                orderItem: o.orderItems[0],
                countdown: moment().add(
                  this.state.competition.competitionType
                    .signupReservationTimeout,
                  "seconds"
                )
              },
              () => {
                this.requireSquadStand();
              }
            );
          })
          .catch(e => {
            this.props.dispatch(setError(e));
            this.setState({
              squad: null,
              squadStand: null,
              requireRegistrationCode: null,
              registrationCode: null,
              countdown: null
            });
          })
          .finally(() => this.setState({ loading: false }))
      );
    }
  }

  requireSquadStand() {
    if (
      this.state.squad &&
      this.state.squad.stands &&
      this.state.squad.stands.length > 0 &&
      !this.state.orderItem.waitingList
    ) {
      getCompetitionSquadStands({
        squadId: this.state.squad.id,
        excludeUnavailable: true
      })
        .then(stands =>
          this.setState({ loading: false, squadStands: stands }, () =>
            this.prepareOrderForm()
          )
        )
        .catch(e => this.props.dispatch(setError(e)));
    } else {
      this.setState({ loading: false }, () => this.prepareOrderForm());
    }
  }

  render() {
    const competition = this.state.competition || {};
    const squad = this.state.squad || {};
    const props = this.props;
    const { submitting, handleSubmit } = this.props;

    const loader = (
      <div className="loader-container" style={{ position: "relative" }}>
        <div className="loader">Loading...</div>
      </div>
    );

    const competitionHeader = this.state.competition ? (
      <Typography variant="subheading" style={{ marginBottom: "10px" }}>
        {competition.name} |{" "}
        {moment(competition.competitionDate)
          .format(competition.competitionEndDate ? "DD.MM" : "L")
          .toString()}
        {competition.competitionEndDate
          ? " - " +
            moment(competition.competitionEndDate)
              .format("L")
              .toString()
          : null}
      </Typography>
    ) : null;

    const nextButton = (
      <Grid item xs={12} sm={7} style={{ paddingRight: "5px" }}>
        <Button
          variant="contained"
          type="submit"
          style={styles.signupBtn}
          disabled={this.state.loading || submitting}
        >
          {this.state.loading || submitting ? <CircularProgress /> : null}
          {props.translate("resources.users.registerForm.next")}
        </Button>
      </Grid>
    );

    const cancelButton = (
      <Grid item xs={12} sm={5}>
        <Button
          variant="contained"
          style={styles.cancelBtn}
          onClick={props.onClose}
        >
          {props.translate("resources.orderList.payOrderPopup.cancel")}
        </Button>
      </Grid>
    );

    const squadsWithWaitingListOnlyAvailable = getSquadsWithWaitingListOnlyAvailable(
      competition.squads || []
    );

    let squadWithWaitingList = (competition.squads || []).concat([
      {
        id: 0,
        name: props.translate(
          "resources.competitionspub.signupForm.waitingListForSquads"
        )
      }
    ]);

    const changeCompetition = values => {
      this.setState({ loading: true }, () => {
        getCompetition(values.competitionId)
          .then(
            c => this.setState({ competition: c }),
            err => this.props.dispatch(setError(err))
          )
          .finally(() => this.setState({ loading: false }));
      });
    };

    const competitionSelect = (
      <>
        <ReferenceInput
          alwaysOn
          id="competitionId"
          name="competitionId"
          source="competitionId"
          label={props.translate("resources.competitionspub.name")}
          reference="competitions"
          validate={[required]}
          perPage={100}
          sort={{ field: "id", order: "DESC" }}
          formName={props.form}
          translate={props.translate}
          options={{
            style: {
              width: "100%"
            },
            suggestionsContainerProps: {
              style: {
                zIndex: 99999
              }
            }
          }}
        >
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <div>
          <Grid container>
            {nextButton}
            {cancelButton}
          </Grid>
        </div>
      </>
    );

    const chooseSquad = values => {
      if (values.squad === 0) {
        this.setState(
          {
            multipleWaitingList: true,
            order: {
              orderItems: [
                {
                  competitionId: competition.id,
                  squadId: 0
                }
              ]
            },
            orderItem: {
              competitionId: competition.id,
              squadId: 0
            }
          },
          () => this.prepareOrderForm()
        );
      } else {
        const squad = competition.squads.find(s => s.id === values.squad);
        this.setState({ squad: squad, squadStand: null }, () =>
          this.requireRegistrationCode()
        );
      }
    };

    const chooseSquadStand = values => {
      if (squad.stands) {
        const stand = squad.stands.find(s => s.id === values.squadStand);
        const order = this.state.order;
        const orderItem = this.state.order.orderItems[0];
        orderItem.squadStand = stand;
        orderItem.squadStandId = stand.id;

        this.setState({ loading: true }, () =>
          updateStand(orderItem.token, order)
            .then(o => {
              this.setState(
                {
                  loading: false,
                  order: o,
                  orderItem: o.orderItems[0],
                  squadStand: stand,
                  countdown: moment().add(
                    this.state.competition.competitionType
                      .signupReservationTimeout,
                    "seconds"
                  )
                },
                () => {
                  this.prepareOrderForm();
                }
              );
            })
            .catch(e => {
              this.props.dispatch(setError(e));
              this.setState({
                squadStand: null
              });
            })
            .finally(() => this.setState({ loading: false }))
        );
      }
    };

    const csSquads = !competition
      ? []
      : squadsWithWaitingListOnlyAvailable.length >= 1 &&
        props.permissions &&
        (isShooter(props.permissions) ||
          isClubAdmin(props.permissions) ||
          isPortalAdmin(props.permissions) ||
          isAdmin(props.permissions))
      ? squadWithWaitingList
      : competition.squads;
    const squadOptions = !csSquads
      ? []
      : csSquads.map(item => {
          const i = { ...item };

          if (item.requireCode) {
            i.name +=
              " (" +
              this.props.translate("resources.squads.fields.requireCode") +
              ")";
          }

          return i;
        });

    const squadSelect = competition ? (
      <>
        <Field
          id="squad"
          name="squad"
          source="squad"
          label={"Squad"}
          validate={[required]}
          options={squadOptions}
          translate={props.translate}
          formName={props.form}
          component={renderCustomAutocompleteInput}
        />
        <Grid container>
          {nextButton}
          {cancelButton}
        </Grid>
      </>
    ) : null;

    const squadStandSelect = competition ? (
      <>
        <Typography
          variant="body1"
          style={{ marginBottom: "10px", marginTop: "5px" }}
        >
          <div>
            <strong>
              {props.translate(
                "resources.competitions.fields.signupForm.timerPrefix"
              )}{" "}
              {this.state.seconds}
              {props.translate(
                "resources.competitions.fields.signupForm.timerSuffix"
              )}{" "}
            </strong>
          </div>
          <div>
            {props.translate(
              "resources.competitions.fields.signupForm.timerInfo"
            )}
          </div>
        </Typography>
        <Field
          id="squadStand"
          name="squadStand"
          source="squadStand"
          label={props.translate("resources.orderEdit.squadStand")}
          validate={[required]}
          options={this.state.squadStands || []}
          translate={props.translate}
          formName={props.form}
          component={renderCustomAutocompleteInput}
        />
        <Grid container>
          {nextButton}
          {cancelButton}
        </Grid>
      </>
    ) : null;

    const reservationCodeInput = (
      <>
        <Typography variant="subheading">
          {competition.nationalCompetition
            ? props.translate(
                "resources.competitionspub.signupForm.nationalReservationCodeSignup"
              )
            : this.state.squad && this.state.squad.requireCode
            ? props.translate(
                "resources.competitionspub.signupForm.squadReservationCodeSignup"
              )
            : props.translate(
                "resources.competitionspub.signupForm.reservationCodeSignup"
              )}
        </Typography>
        <Typography variant="body1">
          {competition.nationalCompetition
            ? props.translate(
                "resources.competitionspub.signupForm.nationalReservationCodeOnly"
              )
            : this.state.squad && this.state.squad.requireCode
            ? props.translate(
                "resources.competitionspub.signupForm.squadReservationCodeOnly"
              )
            : props.translate(
                "resources.competitionspub.signupForm.reservationCodeOnly"
              )}
        </Typography>
        <Field
          name="registrationCode"
          id="registrationCode"
          component={renderTextField}
          validate={[required]}
          type="text"
          label={
            competition.nationalCompetition
              ? props.translate(
                  "resources.competitionspub.signupForm.nationalRegistrationCode"
                )
              : props.translate(
                  "resources.competitionspub.signupForm.registrationCode"
                )
          }
        />
        <Grid container>
          {nextButton}
          {cancelButton}
        </Grid>
      </>
    );

    const checkReservationCode = values => {
      validateRegistrationCode(values.registrationCode, competition.id).then(
        data => {
          if (data) {
            this.setState(
              {
                registrationCode: values.registrationCode
              },
              () => this.prepareOrder()
            );
          }
        },
        e => {
          props.dispatch(setError(new Error(e)));
        }
      );
    };

    const handleUserChange = (event, userId) => {
      if (userId && props.users) {
        const user = props.users.filter(user => user.id === userId)[0];
        props.dispatch(change(props.form, "firstName", user.userFirstName));
        props.dispatch(change(props.form, "lastName", user.userLastName));
        props.dispatch(change(props.form, "email", user.userEmail));
        if (user.club) {
          props.dispatch(change(props.form, "clubId", parseInt(user.club.id)));
        }

        if (
          user.divisionId &&
          competition.division &&
          competition.division.findIndex(
            division => division.id === user.divisionId
          ) !== -1 &&
          (!squad ||
            !squad.divisions ||
            squad.divisions.findIndex(
              division => division.id === user.divisionId
            ) !== -1)
        )
          props.dispatch(change(props.form, "divisionId", user.divisionId));
        else
          props.dispatch(
            change(props.form, "divisionId", this.state.orderItem.divisionId)
          );

        if (
          user.factorId &&
          competition.factor &&
          competition.factor.findIndex(
            factor => factor.id === user.factorId
          ) !== -1 &&
          (!squad ||
            !squad.factors ||
            squad.factors.findIndex(factor => factor.id === user.factorId) !==
              -1)
        )
          props.dispatch(change(props.form, "factorId", user.factorId));
        else
          props.dispatch(
            change(props.form, "factorId", this.state.orderItem.factorId)
          );

        if (
          user.competitionClassId &&
          competition.competitionClass &&
          competition.competitionClass.findIndex(
            competitionClass => competitionClass.id === user.competitionClassId
          ) !== -1 &&
          (!squad ||
            !squad.competitionClasses ||
            squad.competitionClasses.findIndex(
              competitionClass =>
                competitionClass.id === user.competitionClassId
            ) !== -1)
        )
          props.dispatch(
            change(props.form, "competitionClassId", user.competitionClassId)
          );
        else
          change(
            props.form,
            "competitionClassId",
            this.state.orderItem.competitionClassId
          );

        props.dispatch(change(props.form, "country", user.country));
        if (competition.state)
          props.dispatch(change(props.form, "state", user.state));
      } else {
        props.dispatch(
          change(props.form, "divisionId", this.state.orderItem.divisionId)
        );
        props.dispatch(
          change(props.form, "factorId", this.state.orderItem.factorId)
        );
        props.dispatch(
          change(
            props.form,
            "competitionClassId",
            this.state.orderItem.competitionClassId
          )
        );
        props.dispatch(change(props.form, "country", null));
        props.dispatch(change(props.form, "state", null));
        props.dispatch(change(props.form, "firstName", null));
        props.dispatch(change(props.form, "lastName", null));
        props.dispatch(change(props.form, "email", null));
        props.dispatch(change(props.form, "clubId", null));
      }
    };

    let disabled;
    let users = props.users || [];

    if (
      isClubAdmin(props.permissions) &&
      competition &&
      "" + competition.clubId !== props.userData.clubId
    ) {
      users = users.filter(
        u => u.club && "" + u.club.id === props.userData.clubId
      );
    }

    const orderForm = (
      <>
        <Typography variant="subheading">
          {this.state.orderItem.waitingList
            ? props.translate(
                "resources.competitions.fields.signupForm.gotWaitingList"
              )
            : props.translate(
                "resources.competitions.fields.signupForm.gotStartPlace"
              )}
        </Typography>
        <Grid container>
          <Grid item xs={10}>
            <Typography
              variant="body1"
              style={{ marginBottom: "10px", marginTop: "5px" }}
            >
              <div>
                <strong>
                  {props.translate(
                    "resources.competitions.fields.signupForm.timerPrefix"
                  )}{" "}
                  {this.state.seconds}
                  {props.translate(
                    "resources.competitions.fields.signupForm.timerSuffix"
                  )}{" "}
                </strong>
              </div>
              <div>
                {props.translate(
                  "resources.competitions.fields.signupForm.timerInfo"
                )}
              </div>
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <div style={{ position: "relative", display: "inline-flex" }}>
              <CircularProgress
                value={(100 / 60) * this.state.seconds}
                variant="determinate"
                color={
                  this.state.seconds > 30
                    ? "primary"
                    : this.state.seconds > 15
                    ? "warning"
                    : "error"
                }
              />
              <div
                style={{
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  position: "absolute",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <Typography
                  variant="caption"
                  component="div"
                  color={
                    this.state.seconds > 30
                      ? "primary"
                      : this.state.seconds > 15
                      ? "warning"
                      : "error"
                  }
                >
                  {this.state.seconds > 0 ? this.state.seconds : ""}
                </Typography>
              </div>
            </div>
          </Grid>
        </Grid>

        {this.state.squad && !competition.event ? (
          <Field
            disabled={true}
            name="squadName"
            id="squadName"
            component={renderTextField}
            validate={[required]}
            type="text"
            label={props.translate(
              "resources.competitionspub.signupForm.squad"
            )}
          />
        ) : null}

        {this.state.squadStand && !competition.event ? (
          <Field
            disabled={true}
            name="squadStandName"
            id="squadStandName"
            component={renderTextField}
            validate={[required]}
            type="text"
            label={props.translate(
              "resources.competitionspub.signupForm.squadStand"
            )}
          />
        ) : null}

        {(isAdmin(props.permissions) ||
          isClubAdmin(props.permissions) ||
          isPortalAdmin(props.permissions)) && (
          <>
            <Field
              name="userId"
              source="userId"
              label={props.translate(
                "resources.competitionspub.signupForm.User"
              )}
              options={users}
              translate={props.translate}
              formName={props.form}
              component={renderUserAutocompleteInput}
              onChange={handleUserChange}
            />
          </>
        )}
        {this.state.multipleWaitingList ? (
          <Field
            name="squadIds"
            id="squadIds"
            isMulti={true}
            translate={props.translate}
            options={squadsWithWaitingListOnlyAvailable}
            component={renderCustomAutocompleteInput}
            validate={required}
            label={props.translate(
              "resources.competitionspub.signupForm.waitingListForSquads"
            )}
          />
        ) : null}
        {props.permissions && isShooter(props.permissions)
          ? (disabled = true)
          : (disabled = false)}
        <Field
          disabled={disabled}
          name="firstName"
          id="firstName"
          component={renderTextField}
          validate={[required]}
          type="text"
          label={props.translate(
            "resources.competitionspub.signupForm.firstName"
          )}
        />
        <Field
          disabled={disabled}
          name="lastName"
          id="lastName"
          type="text"
          component={renderTextField}
          validate={[required]}
          label={props.translate(
            "resources.competitionspub.signupForm.lastName"
          )}
        />
        <Field
          disabled={disabled}
          name="email"
          id="email"
          component={renderTextField}
          type="text"
          validate={[required, email]}
          label={props.translate("resources.competitionspub.signupForm.email")}
        />
        {!disabled && (
          <Field
            name="country"
            component={renderSelectField}
            label={props.translate(
              "resources.competitionspub.signupForm.country"
            )}
          >
            <option value="" />
            <RenderCountryOptions />
          </Field>
        )}
        <Field
          name="clubId"
          component={renderSelectField}
          label={props.translate("resources.competitionspub.signupForm.club")}
          formName={props.form}
        >
          <option value="" />
          <RenderOptions values={props.clubs} />
        </Field>
        {competition.state ? (
          <Field
            name="state"
            id="state"
            component={renderSelectField}
            validate={[required]}
            label={props.translate(
              "resources.competitionspub.signupForm.state"
            )}
          >
            <option value="" />
            <StateOptions states={austrianStates} />
          </Field>
        ) : null}
        {!competition.event
          ? [
              <Field
                name="divisionId"
                component={renderSelectField}
                validate={[required]}
                label={props.translate(
                  "resources.competitionspub.signupForm.divisions"
                )}
                formName={props.form}
                disabled={
                  this.state.orderItem.squad &&
                  this.state.orderItem.squad.divisions &&
                  this.state.orderItem.squad.divisions.length === 1
                }
              >
                <option value="" />
                <RenderOptions
                  values={
                    this.state.orderItem.squad &&
                    this.state.orderItem.squad.divisions &&
                    this.state.orderItem.squad.divisions.length > 0
                      ? this.state.orderItem.squad.divisions
                      : competition.division
                  }
                />
              </Field>,
              <Field
                name="competitionClassId"
                source="competitionClassId"
                component={renderSelectField}
                validate={[required]}
                label={props.translate(
                  "resources.competitionspub.signupForm.classes"
                )}
                formName={props.form}
                disabled={
                  this.state.orderItem.squad &&
                  this.state.orderItem.squad.competitionClasses &&
                  this.state.orderItem.squad.competitionClasses.length === 1
                }
              >
                <option value="" />
                <RenderOptions
                  values={
                    this.state.orderItem.squad &&
                    this.state.orderItem.squad.competitionClasses &&
                    this.state.orderItem.squad.competitionClasses.length > 0
                      ? this.state.orderItem.squad.competitionClasses
                      : competition.competitionClass
                  }
                />
              </Field>,
              <Field
                name="factorId"
                source="factorId"
                component={renderSelectField}
                validate={[required]}
                label={props.translate(
                  "resources.competitionspub.signupForm.factors"
                )}
                formName={props.form}
                disabled={
                  this.state.orderItem.squad &&
                  this.state.orderItem.squad.factors &&
                  this.state.orderItem.squad.factors.length === 1
                }
              >
                <option value="" />
                <RenderOptions
                  values={
                    this.state.orderItem.squad &&
                    this.state.orderItem.squad.factors &&
                    this.state.orderItem.squad.factors.length > 0
                      ? this.state.orderItem.squad.factors
                      : competition.factor
                  }
                />
              </Field>
            ]
          : null}

        {competition.paymentViaDeposit && competition.payOnSite && (
          <Field
            name="paymentMethod"
            component={renderSelectField}
            validate={[required]}
            label={props.translate(
              "resources.competitionspub.signupForm.paymentMethod"
            )}
          >
            <option value="" />
            <option value="BARZAHLUNG">
              {props.translate("resources.competitionspub.signupForm.cash")}
            </option>
            <option value="VORAUSZAHLUNG">
              {props.translate("resources.competitionspub.signupForm.invoice")}
            </option>
          </Field>
        )}
        {!isAdmin(props.permissions) &&
          !isClubAdmin(props.permissions) &&
          !isPortalAdmin(props.permissions) && (
            <Field
              name="agbs"
              id="agbs"
              label={props.translate("checkout.agbs")}
              component={renderCheckbox}
              validate={[agb]}
              type="checkbox"
            />
          )}

        <Grid container>
          <Grid item xs={12} sm={7} style={{ paddingRight: "5px" }}>
            <Button
              disabled={this.state.loading || submitting}
              variant="contained"
              style={styles.signupBtn}
              type="submit"
            >
              {this.state.loading || submitting ? <CircularProgress /> : null}
              {this.state.orderItem.waitingList ||
              this.state.multipleWaitingList
                ? props.translate(
                    "resources.competitionspub.signupForm.waitingListSignup"
                  )
                : props.translate(
                    "resources.competitionspub.signupForm.signup"
                  )}
            </Button>
          </Grid>
          {cancelButton}
        </Grid>
      </>
    );

    const completeOrderForm = values => {
      const order = this.state.order;
      order.firstName = values.firstName;

      order.lastName = values.lastName;
      order.email = values.email;
      order.status = "RESERVED";
      order.paymentMethod = values.paymentMethod;

      const orderItem = this.state.order.orderItems[0];
      orderItem.firstName = values.firstName;
      orderItem.lastName = values.lastName;
      orderItem.email = values.email;
      orderItem.clubId = values.clubId;
      orderItem.divisionId = values.divisionId;
      orderItem.competitionId = competition.id;
      orderItem.squadIds = values.squadIds;
      orderItem.competitionClassId = values.competitionClassId;
      orderItem.factorId = values.factorId;
      orderItem.squadStandId = values.squadStandId;
      orderItem.userId =
        values.userId && !Array.isArray(values.userId)
          ? values.userId
          : undefined;
      orderItem.reservationCode = this.state.registrationCode;
      orderItem.country = values.country;
      orderItem.state = values.state;
      orderItem.locale = this.props.locale;

      this.setState({ loading: true }, () => {
        new Promise(resolve => {
          props.signup(orderItem.token, order);
          resolve();
        }).finally(() => this.setState({ loading: false }));
      });
    };

    let content = loader;
    let onSubmit = () => {};

    if (!this.state.competition) {
      content = competitionSelect;
      onSubmit = changeCompetition;
    } else if (
      !this.state.squad &&
      !this.state.multipleWaitingList &&
      !this.state.competition.event
    ) {
      content = squadSelect;
      onSubmit = chooseSquad;
    } else if (
      this.state.requireRegistrationCode &&
      !this.state.registrationCode
    ) {
      content = reservationCodeInput;
      onSubmit = checkReservationCode;
    } else if (
      this.state.squad &&
      this.state.squad.stands &&
      this.state.squad.stands.length > 0 &&
      !this.state.squadStand &&
      !this.state.multipleWaitingList &&
      !this.state.competition.event &&
      !this.state.orderItem.waitingList
    ) {
      content = squadStandSelect;
      onSubmit = chooseSquadStand;
    } else if (
      this.state.order ||
      this.state.multipleWaitingList ||
      this.state.competition.event
    ) {
      content = orderForm;
      onSubmit = completeOrderForm;
    }

    return (
      <Card elevation={0}>
        <Title title="TITLE" />
        <CardActions>Actions</CardActions>
        <CardContent>
          <Grid container spacing={0}>
            <Form
              onSubmit={handleSubmit(onSubmit.bind(this))}
              style={{ minHeight: "512px", width: "100%", minWidth: "512px" }}
            >
              <Grid item xs={12}>
                <Typography variant="headline">
                  {props.translate(
                    "resources.competitionspub.signupForm.header"
                  )}
                </Typography>
                {competitionHeader}
                <div style={styles.spacer} />
                {content}
              </Grid>
            </Form>
            <ErrorMessage
              errorMessage={props.errorMessage}
              open={!!props.errorMessage}
              onClose={props.hideError}
              translate={props.translate}
            />
          </Grid>
        </CardContent>
      </Card>
    );
  }
}

CompetitionSignupWizard.propTypes = {
  competition: PropTypes.object.isRequired,
  clubs: PropTypes.array,
  signup: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    myForm: state.form.competitionSignupWizard,
    userData: state.identity ? state.identity.userData : undefined
  };
}

export default translate(
  reduxForm({
    // a unique name for the form
    form: "competitionSignupWizard",
    enableReinitialize: true
  })(connect(mapStateToProps)(CompetitionSignupWizard))
);
