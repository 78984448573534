import React, { Component } from "react";
import {
  ArrayInput,
  AutocompleteInput,
  BooleanInput,
  Create,
  FileInput,
  FormDataConsumer,
  FormTab,
  minLength,
  maxLength,
  minValue,
  NumberInput,
  RadioButtonGroupInput,
  REDUX_FORM_NAME,
  ReferenceArrayInput,
  ReferenceInput,
  SelectArrayInput,
  SelectInput,
  SimpleFormIterator,
  TabbedForm,
  TextInput,
  translate,
  WithPermissions
} from "react-admin";
import {
  globalPaymentMethodValidator,
  link,
  required,
  waitingListRequired
} from "../../validators/validators";
import { connect } from "react-redux";
import { change } from "redux-form";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import { DateInput, DateTimeInput, TimeInput } from "react-admin-date-inputs";
import MomentUtils from "material-ui-pickers/utils/moment-utils";
import deLocale from "date-fns/locale/de";
import RichTextInput from "ra-input-rich-text";
import { isAdmin, isClubAdmin, isPortalAdmin } from "../../utils/utils";
import { setError } from "../../reducer/errorActions";
import {
  getCompetition,
  getDivisionsFor,
  getNotificationTypes,
  getUploadTypes,
  getUserClubs,
  upload
} from "../../utils/restUtils";
import Grid from "@material-ui/core/Grid";
import PageTitle from "../../components/PageTitle";
import moment from "moment-timezone";
import { getCompetitionListImageSrc } from "../../utils/imageUtils";
import CustomToolbar from "../../components/CustomToolbar";
import countries from "./../../components/pages/country-codes-case-lower.json";
import FileFieldInput from "../../components/inputs/FileFieldInput";
import Dialog from "@material-ui/core/Dialog";
import { togglePopup } from "../../reducer/flagActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";

const RenderCountryOptions = () => {
  return countries.map(country => {
    return { id: country.countryCode, name: country.countryName };
  });
};

class CompetitionCreate extends Component {
  state = {
    notificationTypes: [],
    divisionChoices: [],
    squadDivisions: [],
    squadFactors: [],
    squadCompetitionClasses: [],
    factors: null,
    competitionClasses: null,
    uploadTypes: [],
    oldCompetitionDate: null,
    newCompetitionDate: null,
    competition: null
  };

  copySquad = squadID => {
    try {
      let squads = this.props.squads ? this.props.squads : [];
      let data = squads[this.getSquadId(squadID)];
      squads.push(data);
      this.props.dispatch(change(REDUX_FORM_NAME, "squads", squads));
    } catch (e) {
      /*nothing*/
    }
  };

  getSquadId = squadID => {
    return squadID.split("[")[1].split("]")[0];
  };

  getSquad = squadID => {
    try {
      let squads = this.props.squads ? this.props.squads : [];
      return squads[this.getSquadId(squadID)];
    } catch (e) {
      /*nothing*/
    }
  };
  toggleStands = squadID => {
    let squads = this.props.squads ? this.props.squads : [];
    let squad = squads[this.getSquadId(squadID)];
    squad.stands = [{ name: "" }];
    this.props.dispatch(change(REDUX_FORM_NAME, "squads", squads));
  };
  validateSquadSize = (message = "ERROR.SQUAD_STAND_SIZE_NOT_MATCH") => (
    value,
    allValues,
    props,
    field
  ) => {
    const squad = this.getSquad(field);
    return squad &&
      squad.stands &&
      squad.stands.length > 0 &&
      squad.stands.length !== value
      ? props.translate(message)
      : undefined;
  };

  componentDidUpdate = props => {
    if (props.toCopy !== this.props.toCopy) {
      getCompetition(this.props.toCopy).then(
        data => {
          const competition = data;
          this.setState({
            squadFactors: competition.factor || [],
            squadCompetitionClasses: competition.competitionClass || []
          });
          getDivisionsFor(competition.competitionTypeId).then(
            data => {
              this.setState({
                divisionChoices: data.data,
                squadDivisions: data.data.filter(
                  d =>
                    !competition.division_ids ||
                    competition.division_ids.includes(d.id)
                )
              });
            },
            e => {
              this.props.dispatch(setError(e));
            }
          );
          this.props.dispatch(change(REDUX_FORM_NAME, "club", data.clubId));
          this.props.dispatch(change(REDUX_FORM_NAME, "name", data.name));
          this.props.dispatch(
            change(REDUX_FORM_NAME, "information", data.information)
          );
          this.props.dispatch(
            change(REDUX_FORM_NAME, "competitionTypeId", data.competitionTypeId)
          );
          this.props.dispatch(
            change(REDUX_FORM_NAME, "level", data.level ? data.level.id : null)
          );
          this.props.dispatch(change(REDUX_FORM_NAME, "stages", data.stages));
          this.props.dispatch(
            change(REDUX_FORM_NAME, "nominationFee", data.nominationFee)
          );
          this.props.dispatch(
            change(REDUX_FORM_NAME, "announcementLink", data.announcementLink)
          );
          this.props.dispatch(
            change(REDUX_FORM_NAME, "clubWebsiteLink", data.clubWebsiteLink)
          );
          this.props.dispatch(
            change(REDUX_FORM_NAME, "contact.firstName", data.contactFirstName)
          );
          this.props.dispatch(
            change(REDUX_FORM_NAME, "contact.lastName", data.contactLastName)
          );
          this.props.dispatch(
            change(REDUX_FORM_NAME, "contact.email", data.contactEmail)
          );
          this.props.dispatch(
            change(REDUX_FORM_NAME, "contact.telephone", data.contactTelephone)
          );
          this.props.dispatch(change(REDUX_FORM_NAME, "address", data.address));
          this.props.dispatch(change(REDUX_FORM_NAME, "city", data.city));
          this.props.dispatch(change(REDUX_FORM_NAME, "zip", data.zip));
          this.props.dispatch(change(REDUX_FORM_NAME, "country", data.country));
          this.props.dispatch(
            change(REDUX_FORM_NAME, "googleMapsLink", data.googleMapsLink)
          );
          this.props.dispatch(
            change(REDUX_FORM_NAME, "competitionDate", data.competitionDate)
          );
          this.props.dispatch(
            change(
              REDUX_FORM_NAME,
              "competitionEndDate",
              data.competitionEndDate
            )
          );
          this.props.dispatch(
            change(REDUX_FORM_NAME, "registrationStart", data.registrationStart)
          );
          this.props.dispatch(
            change(REDUX_FORM_NAME, "registrationEnd", data.registrationEnd)
          );
          this.props.dispatch(
            change(
              REDUX_FORM_NAME,
              "multipleRegistrations",
              data.multipleRegistrations
            )
          );
          this.props.dispatch(
            change(
              REDUX_FORM_NAME,
              "nationalCompetition",
              data.nationalCompetition
            )
          );
          this.props.dispatch(
            change(REDUX_FORM_NAME, "qualification", data.qualification)
          );
          this.props.dispatch(change(REDUX_FORM_NAME, "state", data.state));
          data.division_ids = [];
          for (let i = 0; i < data.division.length; i++) {
            data.division_ids.push(data.division[i].id);
          }
          this.props.dispatch(
            change(REDUX_FORM_NAME, "division_ids", data.division_ids)
          );
          data.competitionClass_ids = [];
          for (let i = 0; i < data.competitionClass.length; i++) {
            data.competitionClass_ids.push(data.competitionClass[i].id);
          }
          this.props.dispatch(
            change(
              REDUX_FORM_NAME,
              "competitionClass_ids",
              data.competitionClass_ids
            )
          );
          data.factor_ids = [];
          for (let i = 0; i < data.factor.length; i++) {
            data.factor_ids.push(data.factor[i].id);
          }
          this.props.dispatch(
            change(REDUX_FORM_NAME, "factor_ids", data.factor_ids)
          );
          this.props.dispatch(
            change(REDUX_FORM_NAME, "minimumShots", data.minimumShots)
          );
          this.props.dispatch(
            change(REDUX_FORM_NAME, "paymentViaDeposit", data.paymentViaDeposit)
          );
          this.props.dispatch(
            change(REDUX_FORM_NAME, "payOnTime", data.payOnTime)
          );
          this.props.dispatch(
            change(REDUX_FORM_NAME, "payOnSite", data.payOnSite)
          );
          this.props.dispatch(change(REDUX_FORM_NAME, "viewType", "UNLISTED"));

          this.props.dispatch(
            change(
              REDUX_FORM_NAME,
              "firstPaymentReminder",
              data.firstPaymentReminder
            )
          );

          this.props.dispatch(
            change(
              REDUX_FORM_NAME,
              "secondPaymentReminder",
              data.secondPaymentReminder
            )
          );

          this.props.dispatch(
            change(
              REDUX_FORM_NAME,
              "autoSubscriptionCancelation",
              data.autoSubscriptionCancelation
            )
          );

          this.props.dispatch(change(REDUX_FORM_NAME, "squads", data.squads));
          getNotificationTypes().then(
            types => {
              let defaultBlocks = [];
              for (let i = 0; i < types.length; i++) {
                let found = false;
                for (let j = 0; j < data.notificationBlocks.length; j++) {
                  if (data.notificationBlocks[j].type.id == types[i].id) {
                    defaultBlocks.push({
                      text: data.notificationBlocks[j].text,
                      type: this.state.notificationTypes[i]
                    });
                    found = true;
                  }
                }
                if (!found) {
                  defaultBlocks.push({
                    text: "",
                    type: this.state.notificationTypes[i]
                  });
                }
              }
              this.props.dispatch(
                change(REDUX_FORM_NAME, "notificationBlocks", defaultBlocks)
              );
            },
            e => {
              /* do nothing */
            }
          );
        },
        e => {
          this.props.dispatch(setError(e));
        }
      );
    }
  };

  setContactFromClub = (event, key, payload) => {
    let contact = null;
    for (let [index, value] of Object.entries(this.props.clubs.data)) {
      if (value.id == key) {
        contact = value.contact;
      }
    }

    if (contact != null) {
      this.props.dispatch(
        change(
          REDUX_FORM_NAME,
          "contact.firstName",
          contact && contact.firstName ? contact.firstName : null
        )
      );
      this.props.dispatch(
        change(
          REDUX_FORM_NAME,
          "contact.lastName",
          contact && contact.lastName ? contact.lastName : null
        )
      );
      this.props.dispatch(
        change(
          REDUX_FORM_NAME,
          "contact.email",
          contact && contact.email ? contact.email : null
        )
      );
      this.props.dispatch(
        change(
          REDUX_FORM_NAME,
          "contact.telephone",
          contact && contact.telephone ? contact.telephone : null
        )
      );
    }
  };

  setAddressFromClub = (event, key, payload) => {
    let address = null;
    let country = null;
    let city = null;
    let zip = null;
    let googleMapsLink = null;
    let clubWebsiteLink;
    for (let [index, value] of Object.entries(this.props.clubs.data)) {
      if (value.id === key) {
        address = value.matchAddress;
        country = value.matchCountry;
        zip = value.matchZip;
        city = value.matchCity;
        googleMapsLink = value.googleMapsLink;
        clubWebsiteLink = value.websiteLink;
      }
    }

    this.props.dispatch(
      change(REDUX_FORM_NAME, "address", address ? address : null)
    );

    this.props.dispatch(
      change(REDUX_FORM_NAME, "country", country ? country : null)
    );

    this.props.dispatch(change(REDUX_FORM_NAME, "zip", zip ? zip : null));

    this.props.dispatch(change(REDUX_FORM_NAME, "city", city ? city : null));

    this.props.dispatch(
      change(
        REDUX_FORM_NAME,
        "clubWebsiteLink",
        clubWebsiteLink ? clubWebsiteLink : null
      )
    );

    this.props.dispatch(
      change(
        REDUX_FORM_NAME,
        "googleMapsLink",
        googleMapsLink ? googleMapsLink : null
      )
    );
  };

  componentDidMount = () => {
    moment.tz.setDefault("UTC");
    isClubAdmin(this.props.permissions) &&
      getUserClubs().then(
        data => {
          if (data.length > 0) {
            data = data[0];
          } else {
            data = null;
          }

          this.props.dispatch(
            change(
              REDUX_FORM_NAME,
              "clubWebsiteLink",
              data.websiteLink ? data.websiteLink : null
            )
          );
          this.props.dispatch(
            change(
              REDUX_FORM_NAME,
              "contact.firstName",
              data.contact && data.contact.firstName
                ? data.contact.firstName
                : null
            )
          );
          this.props.dispatch(
            change(
              REDUX_FORM_NAME,
              "contact.lastName",
              data.contact && data.contact.lastName
                ? data.contact.lastName
                : null
            )
          );
          this.props.dispatch(
            change(
              REDUX_FORM_NAME,
              "contact.email",
              data.contact && data.contact.email ? data.contact.email : null
            )
          );
          this.props.dispatch(
            change(
              REDUX_FORM_NAME,
              "contact.telephone",
              data.contact && data.contact.telephone
                ? data.contact.telephone
                : null
            )
          );
          this.props.dispatch(
            change(
              REDUX_FORM_NAME,
              "address",
              data.matchAddress ? data.matchAddress : null
            )
          );
          this.props.dispatch(
            change(
              REDUX_FORM_NAME,
              "city",
              data.matchCity ? data.matchCity : null
            )
          );
          this.props.dispatch(
            change(REDUX_FORM_NAME, "zip", data.matchZip ? data.matchZip : null)
          );
          this.props.dispatch(
            change(
              REDUX_FORM_NAME,
              "country",
              data.matchCountry ? data.matchCountry : null
            )
          );
          this.props.dispatch(
            change(
              REDUX_FORM_NAME,
              "googleMapsLink",
              data.googleMapsLink ? data.googleMapsLink : null
            )
          );
        },
        () => {}
      );
    this.getUploadTypesData();
  };

  getDivisions = id => {
    if (id) {
      getDivisionsFor(id).then(
        data => {
          this.setState({
            divisionChoices: data.data,
            squadDivisions: []
          });
          this.props.dispatch(change(REDUX_FORM_NAME, "division_ids", null));
        },
        e => {
          this.props.dispatch(setError(e));
        }
      );
    } else {
      this.setState({ divisionChoices: [], squadDivisions: [] });
    }
  };

  getUploadTypesData = () => {
    let defaultUploadType = [];
    getUploadTypes(0, 100, "sortingOrder", "asc", {}).then(
      data => {
        this.setState({
          uploadTypes: data.data
        });

        for (let i = 0; i < data.data.length; i++) {
          defaultUploadType.push({
            documentRaw: { rawFile: { documentId: null } },
            document: {},
            type: this.state.uploadTypes[i]
          });
        }
        this.props.dispatch(
          change(REDUX_FORM_NAME, "competitionUploads", defaultUploadType)
        );
      },
      e => {
        this.props.dispatch(setError(e));
      }
    );
  };

  uploadFileSuccess = (data, rawFile, index) => {
    let formData = new FormData();
    const filename = rawFile.name.split(".")[0];
    formData.append("file", rawFile);
    formData.append("name", rawFile.name);

    rawFile.documentId = data.id;
    rawFile.errorMsg = null;
    const { dispatch } = this.props;
    setTimeout(() => {
      dispatch(setError(null));
      dispatch(
        change(
          REDUX_FORM_NAME,
          "competitionUploads[" + index + "].document.fileName",
          rawFile.name
        )
      );
      dispatch(
        change(
          REDUX_FORM_NAME,
          "competitionUploads[" + index + "].document.path",
          data.key
        )
      );
      dispatch(change(REDUX_FORM_NAME, "tmp", new Date().getTime()));
    }, 10);
  };

  uploadFileError = (e, rawFile) => {
    rawFile.documentId = null;
    rawFile.errorMsg = e.message;

    const { dispatch } = this.props;
    dispatch(setError({ message: e.message }));
    setTimeout(() => {
      this.hideErrorMessage();
    }, 2000);
    dispatch(change(REDUX_FORM_NAME, this.props.source, null));
    dispatch(change(REDUX_FORM_NAME, this.props.previewSource, null));
  };

  uploadFile(file, index) {
    file = file[0];
    const prefix = (Math.random() + 1).toString(36).substring(2);
    const filename = prefix + "/" + file.name.split(".")[0];

    let formData = new FormData();
    formData.append("file", file);
    formData.append("name", filename);

    upload(formData)
      .then(data => this.uploadFileSuccess(data, file, index))
      .catch(e => {
        this.setState({ loading: false });
      });
  }

  hideErrorMessage = () => {
    this.props.dispatch(setError(null));
  };

  updateDates = (competition, oldCompetitionDate, newCompetitionDate) => {
    let diff = moment(
      moment(newCompetitionDate)
        .local()
        .format("YYYY-MM-DD")
    ).diff(
      moment(
        moment(oldCompetitionDate)
          .local()
          .format("YYYY-MM-DD")
      ),
      "days"
    );

    if (competition) {
      let comp = competition;

      if (comp.competitionEndDate) {
        const competitionEndDate = moment(comp.competitionEndDate)
          .local()
          .add(diff, "days");
        comp.competitionEndDate = competitionEndDate.toJSON();
        this.props.dispatch(
          change(REDUX_FORM_NAME, "competitionEndDate", competitionEndDate)
        );
      }

      if (comp.registrationStart) {
        const registrationStart = moment(comp.registrationStart)
          .local()
          .add(diff, "days");
        comp.registrationStart = registrationStart.toJSON();
        this.props.dispatch(
          change(REDUX_FORM_NAME, "registrationStart", registrationStart)
        );
      }

      if (comp.registrationEnd) {
        const registrationEnd = moment(comp.registrationEnd)
          .local()
          .add(diff, "days");
        comp.registrationEnd = registrationEnd.toJSON();
        this.props.dispatch(
          change(REDUX_FORM_NAME, "registrationEnd", registrationEnd)
        );
      }

      if (this.props.squads) {
        for (let squad of this.props.squads) {
          if (squad.startDate) {
            squad.startDate = moment(squad.startDate)
              .local()
              .add(diff, "days")
              .toJSON();
          }
        }
      }
    }
  };

  errorLabel = label => <span style={{ color: "red" }}>{label}</span>;

  getSquadDivisions = ids => {
    const divisions = this.state.divisionChoices.filter(d =>
      ids.includes(d.id)
    );
    this.setState({
      squadDivisions: divisions
    });
  };

  getSquadFactors = ids => {
    if (this.state.factors) {
      const factors = this.state.factors.filter(f => ids.includes(f.id));
      this.setState({
        squadFactors: factors
      });
    }
  };

  getSquadCompetitionClasses = ids => {
    if (this.state.competitionClasses) {
      const competitionClasses = this.state.competitionClasses.filter(cc =>
        ids.includes(cc.id)
      );
      this.setState({
        squadCompetitionClasses: competitionClasses
      });
    }
  };

  render = () => {
    return (
      <WithPermissions
        render={({ permissions }) => (
          <Grid>
            <PageTitle
              title={this.props.translate(
                "resources.competitions.createPage.headline"
              )}
            />

            <Dialog
              open={this.props.popupChangeDates}
              onClose={() => {
                this.props.dispatch(togglePopup("popupChangeDates"));
              }}
            >
              <DialogTitle>
                {this.props.translate(
                  "resources.competitions.editPage.competitionDateChanged"
                )}
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  {this.props.translate(
                    "resources.competitions.editPage.updateAllDates"
                  )}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    this.props.dispatch(togglePopup("popupChangeDates"));
                  }}
                  color="primary"
                >
                  NEIN
                </Button>
                <Button
                  onClick={() => {
                    this.updateDates(
                      this.state.competition,
                      this.state.oldCompetitionDate,
                      this.state.newCompetitionDate
                    );
                    this.props.dispatch(togglePopup("popupChangeDates"));
                  }}
                  color="primary"
                  autoFocus
                >
                  JA
                </Button>
              </DialogActions>
            </Dialog>

            <Create {...this.props}>
              <TabbedForm
                redirect="list"
                validate={globalPaymentMethodValidator}
                toolbar={<CustomToolbar {...this.props} />}
              >
                <FormTab label="resources.competitions.fields.tabs.competition">
                  {isAdmin(permissions) ? (
                    <ReferenceInput
                      source="club"
                      reference="clubs"
                      allowEmpty={false}
                      resource={this.props.resource}
                      validate={isAdmin(permissions) ? required() : []}
                      filter={{ active: true }}
                      perPage={100}
                      sort={{ field: "name", order: "ASC" }}
                      onChange={(event, key, payload) => {
                        this.setContactFromClub(event, key, payload);
                        this.setAddressFromClub(event, key, payload);
                      }}
                    >
                      <AutocompleteInput optionText="name" />
                    </ReferenceInput>
                  ) : null}

                  <TextInput
                    source="name"
                    validate={[required(), minLength(2)]}
                  />
                  <RichTextInput
                    source="information"
                    toolbar={[["bold", "italic", "underline", "link"]]}
                    validate={isClubAdmin(permissions) ? required() : []}
                  />
                  <ReferenceInput
                    source="competitionTypeId"
                    reference="competitionTypes"
                    perPage={200}
                    sort={{ order: "asc", field: "name" }}
                    validate={required()}
                    onChange={(event, value) => this.getDivisions(value)}
                  >
                    <SelectInput optionText="name" />
                  </ReferenceInput>

                  <FormDataConsumer>
                    {({ formData, ...rest }) => {
                      var image = null;
                      var selectedCompetitionType;
                      if (formData && formData.competitionTypeId) {
                        var obj = this.props.competitionTypes.data;

                        Object.keys(obj).forEach(function(key) {
                          if (obj[key].id == formData.competitionTypeId) {
                            selectedCompetitionType = obj[key];
                          }
                        });

                        if (
                          selectedCompetitionType.defaultImageId != 0 &&
                          selectedCompetitionType.images != null
                        ) {
                          var imageId = selectedCompetitionType.defaultImageId;
                          image = selectedCompetitionType.logo;
                        }
                      }
                      return formData &&
                        formData.competitionTypeId &&
                        selectedCompetitionType.logo ? (
                        <img
                          imageHeight={150}
                          src={getCompetitionListImageSrc(
                            selectedCompetitionType.logo
                          )}
                        ></img>
                      ) : null;
                    }}
                  </FormDataConsumer>

                  <ReferenceInput
                    validate={isClubAdmin(permissions) ? required() : []}
                    perPage={200}
                    sort={{ field: "name", order: "ASC" }}
                    reference="levels"
                    source="level"
                  >
                    <SelectInput optionText="name" />
                  </ReferenceInput>
                  <NumberInput
                    source="stages"
                    step={1}
                    validate={isClubAdmin(permissions) ? required() : []}
                  />
                  <NumberInput
                    source="nominationFee"
                    validate={isClubAdmin(permissions) ? required() : []}
                  />
                  <TextInput source="clubWebsiteLink" />
                </FormTab>
                <FormTab label="resources.competitions.fields.contactHeader">
                  <TextInput
                    source="contact.firstName"
                    validate={isClubAdmin(permissions) ? required() : []}
                  />
                  <TextInput
                    source="contact.lastName"
                    validate={isClubAdmin(permissions) ? required() : []}
                  />
                  <TextInput
                    source="contact.email"
                    validate={isClubAdmin(permissions) ? required() : []}
                  />
                  <TextInput
                    source="contact.telephone"
                    validate={isClubAdmin(permissions) ? required() : []}
                  />
                </FormTab>
                <FormTab label="resources.competitions.fields.addressHeader">
                  <TextInput
                    source="address"
                    validate={isClubAdmin(permissions) ? required() : []}
                  />
                  <TextInput
                    source="city"
                    validate={isClubAdmin(permissions) ? required() : []}
                  />
                  <TextInput
                    source="zip"
                    validate={isClubAdmin(permissions) ? required() : []}
                  />
                  <SelectInput
                    source="country"
                    validate={isClubAdmin(permissions) ? required() : []}
                    choices={RenderCountryOptions()}
                  ></SelectInput>
                  <TextInput
                    source="googleMapsLink"
                    validate={isClubAdmin(permissions) ? required() : []}
                  />
                </FormTab>
                <FormTab label="resources.competitions.fields.dateHeader">
                  <FormDataConsumer>
                    {({ formData, ...rest }) => {
                      return (
                        <DateInput
                          label={this.props.translate(
                            "resources.competitions.fields.date"
                          )}
                          source="competitionDate"
                          providerOptions={{
                            utils: MomentUtils,
                            locale: deLocale
                          }}
                          options={{
                            format: "DD.MM.YYYY",
                            ampm: false,
                            clearable: true,
                            disablePast: true
                          }}
                          validate={required()}
                          onChange={value => {
                            if (
                              formData.competitionDate &&
                              !moment(formData.competitionDate)
                                .local()
                                .isSame(moment(value).local(), "day")
                            ) {
                              this.setState({
                                oldCompetitionDate: formData.competitionDate,
                                newCompetitionDate: value,
                                competition: formData
                              });
                              this.props.dispatch(
                                togglePopup("popupChangeDates")
                              );
                            }
                          }}
                          {...rest}
                        />
                      );
                    }}
                  </FormDataConsumer>
                  <DateInput
                    label={this.props.translate(
                      "resources.competitions.fields.endDate"
                    )}
                    source="competitionEndDate"
                    providerOptions={{ utils: MomentUtils, locale: deLocale }}
                    options={{
                      format: "DD.MM.YYYY",
                      ampm: false,
                      clearable: true,
                      disablePast: true
                    }}
                  />
                  <DateTimeInput
                    label={this.props.translate(
                      "resources.competitions.fields.registrationStart"
                    )}
                    source="registrationStart"
                    providerOptions={{ utils: MomentUtils, locale: deLocale }}
                    options={{
                      disablePast: true,
                      format: "DD.MM.YYYY, HH:mm:ss",
                      ampm: false,
                      clearable: true
                    }}
                    validate={required()}
                  />
                  <DateTimeInput
                    label={this.props.translate(
                      "resources.competitions.fields.registrationEnd"
                    )}
                    source="registrationEnd"
                    providerOptions={{ utils: MomentUtils, locale: deLocale }}
                    options={{
                      format: "DD.MM.YYYY, HH:mm:ss",
                      ampm: false,
                      clearable: true,
                      disablePast: true
                    }}
                    validate={required()}
                  />
                </FormTab>
                <FormTab label="resources.competitions.fields.additionalInfo">
                  <FormDataConsumer>
                    {({ formData, ...rest }) =>
                      formData.competitionTypeId ? (
                        <SelectArrayInput
                          source="division_ids"
                          choices={this.state.divisionChoices}
                          validate={required()}
                          optionText="name"
                          {...rest}
                          onChange={(event, value) =>
                            this.getSquadDivisions(value)
                          }
                        />
                      ) : (
                        <SelectArrayInput
                          label="ERROR.SELECT_COMPETITION_TYPE"
                          optionText="name"
                          disabled={true}
                          {...rest}
                        />
                      )
                    }
                  </FormDataConsumer>
                  <ReferenceArrayInput
                    perPage={200}
                    source="competitionClass_ids"
                    validate={required()}
                    reference="competitionClasses"
                    sort={{ field: "name", order: "ASC" }}
                    onChange={(event, value) =>
                      this.getSquadCompetitionClasses(value)
                    }
                  >
                    <SelectArrayInput optionText="name" />
                  </ReferenceArrayInput>
                  <ReferenceArrayInput
                    perPage={200}
                    source="factor_ids"
                    reference="factors"
                    sort={{ order: "asc", field: "name" }}
                    validate={required()}
                    onChange={(event, value) => this.getSquadFactors(value)}
                  >
                    <SelectArrayInput optionText="name" />
                  </ReferenceArrayInput>
                  <NumberInput source="minimumShots" step={1} />

                  <BooleanInput source="multipleRegistrations" />
                  <BooleanInput source="nationalCompetition" />
                  <BooleanInput source="qualification" />
                  <BooleanInput source="state" />
                  <Divider />
                  {isPortalAdmin(permissions) || isAdmin(permissions) ? (
                    <>
                      <BooleanInput
                        label={this.props.translate(
                          "resources.competitions.fields.paid"
                        )}
                        source="paid"
                        defaultValue={false}
                      />
                      <Divider />
                    </>
                  ) : null}
                  <BooleanInput
                    source="firstPaymentReminder"
                    defaultValue={false}
                  />
                  <BooleanInput
                    source="secondPaymentReminder"
                    defaultValue={false}
                  />

                  <FormDataConsumer>
                    {({ formData, ...rest }) => {
                      {
                        return formData.firstPaymentReminder ||
                          formData.secondPaymentReminder ? (
                          <BooleanInput
                            source="autoSubscriptionCancelation"
                            defaultValue={false}
                            label={this.props.translate(
                              "resources.competitions.fields.autoSubscriptionCancelation"
                            )}
                          />
                        ) : (
                          <BooleanInput
                            source="disabledAutoSubscriptionCancelation"
                            defaultValue={false}
                            options={{
                              disabled: true
                            }}
                            label={this.props.translate(
                              "resources.competitions.fields.autoSubscriptionCancelation"
                            )}
                          />
                        );
                      }
                    }}
                  </FormDataConsumer>

                  <Divider />

                  <BooleanInput
                    label={
                      !this.props.payOnSite &&
                      !this.props.paymentViaDeposit &&
                      this.props.submitFailed
                        ? this.errorLabel(
                            this.props.translate(
                              "resources.competitions.fields.payOnSite"
                            )
                          )
                        : "resources.competitions.fields.payOnSite"
                    }
                    source="payOnSite"
                  />
                  <ReferenceInput
                    source="club"
                    reference="clubs"
                    perPage={100}
                    sort={{ field: "name", order: "ASC" }}
                    resource={this.props.resource}
                  >
                    <FormDataConsumer>
                      {({ formData, ...rest }) => {
                        if (
                          (rest.choices && formData && formData.club) ||
                          rest.choices.length === 1
                        )
                          return rest.choices.find(club => {
                            if (
                              isClubAdmin(permissions) &&
                              rest.choices.length == 1
                            )
                              return (
                                rest.choices[0].bankInformation &&
                                rest.choices[0].bankInformation.name
                              );
                            else
                              return (
                                club.id == formData.club &&
                                club.bankInformation &&
                                club.bankInformation.name
                              );
                          }) ? (
                            <BooleanInput
                              label={
                                !this.props.payOnSite &&
                                !this.props.paymentViaDeposit &&
                                this.props.submitFailed
                                  ? this.errorLabel(
                                      this.props.translate(
                                        "resources.competitions.fields.paymentViaDeposit"
                                      )
                                    )
                                  : "resources.competitions.fields.paymentViaDeposit"
                              }
                              source="paymentViaDeposit"
                              disabled={true}
                            />
                          ) : null;
                      }}
                    </FormDataConsumer>
                  </ReferenceInput>
                  <NumberInput
                    source="payOnTime"
                    step={1}
                    defaultValue={9}
                    validate={[required(), minValue(9)]}
                  />
                  <RadioButtonGroupInput
                    source="viewType"
                    defaultValue={"UNLISTED"}
                    label="resources.competitions.fields.viewType.label"
                    choices={[
                      {
                        id: "ALL",
                        name: this.props.translate(
                          "resources.competitions.fields.viewType.all"
                        )
                      },
                      {
                        id: "INTERNAL",
                        name: this.props.translate(
                          "resources.competitions.fields.viewType.internal"
                        )
                      },
                      {
                        id: "EXTERNAL",
                        name: this.props.translate(
                          "resources.competitions.fields.viewType.external"
                        )
                      },
                      {
                        id: "UNLISTED",
                        name: this.props.translate(
                          "resources.competitions.fields.viewType.unlisted"
                        )
                      }
                    ]}
                  />
                </FormTab>
                <FormTab label="resources.competitions.fields.squadHeader">
                  <ArrayInput source="squads">
                    <SimpleFormIterator>
                      <FormDataConsumer>
                        {form => {
                          return (
                            <>
                              <h1>
                                {parseInt(this.getSquadId(form.id)) + 1}. Squad
                              </h1>
                              <Divider />
                            </>
                          );
                        }}
                      </FormDataConsumer>
                      <TextInput
                        source="name"
                        label="resources.squads.fields.name"
                        validate={[required(), minLength(1)]}
                      />
                      <TextInput
                        source="information"
                        label="resources.squads.fields.information"
                        validate={required()}
                      />
                      <NumberInput
                        source="squadSize"
                        label="resources.squads.fields.squadSize"
                        validate={[
                          required(),
                          minValue(0),
                          this.validateSquadSize()
                        ]}
                      />
                      <NumberInput
                        source="maxWaitingListSize"
                        label="resources.squads.fields.maxWaitingListSize"
                        validate={[waitingListRequired(), minValue(0)]}
                      />
                      <DateInput
                        source="startDate"
                        label="resources.squads.fields.startDate"
                        options={{ disablePast: true, format: "DD.MM.YYYY" }}
                        providerOptions={{
                          utils: MomentUtils,
                          locale: deLocale
                        }}
                        validate={required()}
                      />
                      <TimeInput
                        source="startTime"
                        label="resources.squads.fields.startTime"
                        validate={required()}
                        options={{ ampm: false }}
                        providerOptions={{
                          utils: MomentUtils,
                          locale: deLocale
                        }}
                      />
                      <TimeInput
                        source="endTime"
                        label="resources.squads.fields.endTime"
                        validate={required()}
                        options={{ ampm: false }}
                        providerOptions={{
                          utils: MomentUtils,
                          locale: deLocale
                        }}
                      />
                      <SelectArrayInput
                        source="divisionIds"
                        label="resources.squads.fields.division"
                        choices={this.state.squadDivisions}
                        optionText="name"
                        resettable
                      />
                      <SelectArrayInput
                        source="competitionClassIds"
                        label="resources.squads.fields.competitionClass"
                        choices={this.state.squadCompetitionClasses}
                        optionText="name"
                        resettable
                      />
                      <SelectArrayInput
                        source="factorIds"
                        label="resources.squads.fields.factor"
                        choices={this.state.squadFactors}
                        optionText="name"
                        resettable
                      />

                      <BooleanInput
                        source="requireCode"
                        label="resources.squads.fields.requireCode"
                      />
                      <FormDataConsumer>
                        {({ formData, getSource, ...rest }) => {
                          const id = rest.id;
                          const squad = this.getSquad(id);
                          if (
                            squad &&
                            squad.stands &&
                            squad.stands.length > 0
                          ) {
                            return (
                              <ArrayInput
                                source={getSource("stands")}
                                label="resources.squads.fields.stands"
                              >
                                <SimpleFormIterator>
                                  <TextInput
                                    source="name"
                                    label="resources.squads.fields.name"
                                    validate={[
                                      required(),
                                      minLength(1),
                                      maxLength(255)
                                    ]}
                                  />
                                </SimpleFormIterator>
                              </ArrayInput>
                            );
                          } else {
                            return (
                              <Button
                                onClick={() => this.toggleStands(id)}
                                variant="outlined"
                              >
                                {this.props.translate(
                                  "resources.squads.addStands"
                                )}
                              </Button>
                            );
                          }
                        }}
                      </FormDataConsumer>
                      <FormDataConsumer>
                        {({ formData, ...rest }) => {
                          const id = rest.id;
                          return (
                            <div
                              style={{
                                marginBottom: "70px",
                                marginTop: "25px",
                                display: "flex"
                              }}
                            >
                              <Button onClick={() => this.copySquad(id)}>
                                {this.props.translate(
                                  "resources.squads.copySquad"
                                )}
                              </Button>
                              <Button onClick={() => this.removeSquad(id)}>
                                {this.props.translate(
                                  "resources.squads.removeSquad"
                                )}
                              </Button>
                            </div>
                          );
                        }}
                      </FormDataConsumer>
                    </SimpleFormIterator>
                  </ArrayInput>
                </FormTab>
                <FormTab label="resources.competitions.uploadTypes">
                  <TextInput source="announcementLink" />
                  <TextInput
                    source="resultListLink"
                    label="resources.squads.fields.resultListLink"
                    validate={link()}
                  />
                  <ArrayInput
                    source="competitionUploads"
                    disableRemove
                    disableAdd
                    label=""
                    style={{ width: "480px" }}
                  >
                    <SimpleFormIterator disableAdd={true} disableRemove={true}>
                      <FormDataConsumer source="">
                        {({ formData, getSource, ...rest }) => {
                          let re = /(\d+)/;
                          let found = getSource().split(re);
                          const index = found[1];
                          if (formData.competitionUploads !== undefined) {
                            return [
                              <div>
                                <h3>
                                  {formData.competitionUploads[index].type.name}
                                </h3>
                                <FileInput
                                  maxSize={20000000}
                                  source={
                                    "competitionUploads[" +
                                    index +
                                    "].documentRaw"
                                  }
                                  label={""}
                                  multiple={false}
                                  options={{
                                    onDropAccepted: file =>
                                      this.uploadFile(file, index)
                                  }}
                                >
                                  <FileFieldInput
                                    source={
                                      "competitionUploads[" +
                                      index +
                                      "].documentRaw.rawFile"
                                    }
                                    title={
                                      "formData.competitionUploads[" +
                                      index +
                                      "].type.name"
                                    }
                                  />
                                </FileInput>
                                {formData.competitionUploads[index].type
                                  .showName ? (
                                  <p style={{ marginTop: 0 }}>
                                    <label>
                                      Bei den "Frei-Namen Download 1-10" Feldern
                                      werden im Bewerbs-Download Bereich die
                                      File-Namen der bereitgestellten Dateien
                                      angezeigt. Bitte wählen Sie diese daher
                                      dementsprechend!
                                    </label>
                                  </p>
                                ) : null}
                              </div>
                            ];
                          } else {
                            return null;
                          }
                        }}
                      </FormDataConsumer>
                    </SimpleFormIterator>
                  </ArrayInput>
                </FormTab>
              </TabbedForm>
            </Create>
          </Grid>
        )}
      />
    );
  };
}

const mapStateToProps = state => {
  return {
    httpErrorMsg: state.error.httpError.message,
    permissions: state.identity.userData.permissions,
    competitionTypes: state.admin.resources.competitionTypes,
    clubs: state.admin.resources.clubs,
    payOnSite:
      state.form[REDUX_FORM_NAME] && state.form[REDUX_FORM_NAME].values
        ? state.form[REDUX_FORM_NAME].values.payOnSite
        : null,
    paymentViaDeposit:
      state.form[REDUX_FORM_NAME] && state.form[REDUX_FORM_NAME].values
        ? state.form[REDUX_FORM_NAME].values.paymentViaDeposit
        : null,

    submitFailed: state.form[REDUX_FORM_NAME]
      ? state.form[REDUX_FORM_NAME].submitFailed
      : null,

    squads:
      state.form[REDUX_FORM_NAME] && state.form[REDUX_FORM_NAME].values
        ? state.form[REDUX_FORM_NAME].values.squads
        : null,

    popupChangeDates: state.flags.popupOpen.popupChangeDates
  };
};

export default connect(mapStateToProps)(translate(CompetitionCreate));
