import React, { useState } from "react";
import {
  AutocompleteInput,
  changeLocale,
  DisabledInput,
  Edit,
  REDUX_FORM_NAME,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  translate
} from "react-admin";
import compose from "recompose/compose";
import PageTitle from "../../components/PageTitle";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import Button from "@material-ui/core/Button";
import { SaveShooterOrderItemButton } from "./SaveShooterOrderItemButton";
import { togglePopup } from "../../reducer/flagActions";
import GenericPopup from "../../components/GenericPopup";
import { connect } from "react-redux";
import { setSuccess } from "../../reducer/successActions";
import { setError } from "../../reducer/errorActions";
import { cancelOrderItem, getCompetitionSquads } from "../../utils/restUtils";
import moment from "moment";
import Warning from "@material-ui/icons/Warning";
import Typography from "@material-ui/core/Typography";
import { change, Field, reduxForm } from "redux-form";
import { renderSelectField } from "../../components/inputs/renderMaterialFields";
import { isClubAdmin } from "../../utils/utils";

const WithProps = ({ children, ...props }) => children(props);

const checkLocale = props => {
  if (props.record.locale && props.locale !== props.record.locale) {
    props.changeLocale(props.record.locale);
  }

  return null;
};

const CustomToolbar = props => (
  <Toolbar style={{ justifyContent: "space-between" }} {...props}>
    {checkLocale(props)}
    {!props.record.archive && !props.view && !props.record.registrationEnded ? (
      <SaveShooterOrderItemButton />
    ) : null}
    {!props.record.cancelled &&
    !props.cancelled &&
    !props.record.archive &&
    !props.view &&
    !props.record.registrationEnded ? (
      <Button
        onClick={() => props.dispatch(togglePopup("cancelOrderItemPopup"))}
        variant="contained"
        style={{ color: "#4F6982", margin: "10px" }}
      >
        Abmelden
      </Button>
    ) : null}
    <Button
      variant="contained"
      onClick={() => props.history.push("/myCompetitions")}
    >
      Abbrechen
    </Button>
  </Toolbar>
);

let ShooterOrderItemEdit = compose(
  translate,
  connect(mapStateToProps)
)(({ translate, history, dispatch, cancelOrderItemPopup, state, ...props }) => {
  const view =
    new URLSearchParams(props.location.search).get("mode") === "view";
  const changeLocale = props.changeLocale;

  const [cancelled, setCancelled] = useState(false);

  const chancedSquad = (record, value) => {
    getCompetitionSquads({
      competitionId: record.competition.id,
      orderItemId: record.id,
      excludeFullSquads: false
    }).then(
      squads => {
        const squad = squads.find(s => s.id === value);
        if (squad) {
          record.squad = squad;
          record.divisionId = record.division.id;
          record.factorId = record.factor.id;
          record.competitionClassId = record.competitionClass.id;

          dispatch(change("shooterOrderItemForm", "squad", squad));
          dispatch(
            change("shooterOrderItemForm", "division.id", record.division.id)
          );
          dispatch(
            change("shooterOrderItemForm", "factor.id", record.factor.id)
          );
          dispatch(
            change(
              "shooterOrderItemForm",
              "competitionClass.id",
              record.competitionClass.id
            )
          );
          dispatch(change("shooterOrderItemForm", "squadStand.id", null));
        }
      },
      e => dispatch(setError(e))
    );
  };

  return (
    <Grid>
      <PageTitle title={translate("resources.orderEdit.headline")} />
      <Edit {...props}>
        <WithProps>
          {({ record, ...props }) => (
            <SimpleForm
              redirect={"/myCompetitions"}
              record={record}
              toolbar={
                <CustomToolbar
                  cancelled={cancelled}
                  view={view}
                  dispatch={dispatch}
                  history={history}
                  changeLocale={changeLocale}
                />
              }
              {...props}
            >
              <DisabledInput
                label={translate("resources.orderEdit.orderItemNumber")}
                source="id"
              />
              <TextInput
                disabled={
                  cancelled ||
                  record.cancelled ||
                  record.archive ||
                  view ||
                  record.registrationEnded
                }
                source="firstName"
                label={translate("resources.orderEdit.firstName")}
                validate={required()}
              />
              <TextInput
                disabled={
                  cancelled ||
                  record.cancelled ||
                  record.archive ||
                  view ||
                  record.registrationEnded
                }
                source="lastName"
                label={translate("resources.orderEdit.lastName")}
                validate={required()}
              />
              <DisabledInput
                source="email"
                label={translate("resources.orderEdit.email")}
                validate={required()}
              />
              <DisabledInput
                source="competition.name"
                label={translate("resources.orderEdit.competition")}
                optionText="name"
                helperText={
                  moment(record.competition.competitionDate)
                    .format("L")
                    .toString() +
                  (record.competition.competitionEndDate
                    ? " - " +
                      moment(record.competition.competitionEndDate)
                        .format("L")
                        .toString()
                    : "")
                }
              />

              {!record.competition.event ? (
                [
                  <ReferenceInput
                    label={translate("resources.orderEdit.divisions")}
                    source="division.id"
                    reference="divisions"
                    perPage={100}
                    sort={{ field: "name", order: "ASC" }}
                    filter={{
                      competitionId: record.competition.id,
                      squadId: record.squad.id
                    }}
                    {...props}
                  >
                    <AutocompleteInput
                      options={{
                        disabled:
                          cancelled ||
                          record.cancelled ||
                          record.archive ||
                          view ||
                          record.registrationEnded
                      }}
                      optionText="name"
                    />
                  </ReferenceInput>,
                  <ReferenceInput
                    label={translate("resources.orderEdit.competitionClass")}
                    source="competitionClass.id"
                    reference="competitionClasses"
                    perPage={100}
                    sort={{ field: "name", order: "ASC" }}
                    filter={{
                      competitionId: record.competition.id,
                      squadId: record.squad.id
                    }}
                    {...props}
                  >
                    <AutocompleteInput
                      options={{
                        disabled:
                          cancelled ||
                          record.cancelled ||
                          record.archive ||
                          view ||
                          record.registrationEnded
                      }}
                      optionText="name"
                    />
                  </ReferenceInput>,
                  <ReferenceInput
                    label={translate("resources.orderEdit.factor")}
                    source="factor.id"
                    reference="factors"
                    perPage={100}
                    sort={{ field: "name", order: "ASC" }}
                    filter={{
                      competitionId: record.competition.id,
                      squadId: record.squad.id
                    }}
                    {...props}
                  >
                    <AutocompleteInput
                      options={{
                        disabled:
                          cancelled ||
                          record.cancelled ||
                          record.archive ||
                          view ||
                          record.registrationEnded
                      }}
                      optionText="name"
                    />
                  </ReferenceInput>,
                  record.squad.stands && record.squad.stands.length > 0 ? (
                    <SelectInput
                      name="squadStand"
                      source="squadStand.id"
                      validate={required()}
                      choices={record.squad.stands}
                      label={translate(
                        "resources.competitionspub.signupForm.squadStand"
                      )}
                      {...props}
                      disabled={
                        cancelled ||
                        record.cancelled ||
                        record.archive ||
                        view ||
                        record.registrationEnded
                      }
                    />
                  ) : null,
                  <ReferenceInput
                    perPage={100}
                    label={translate("resources.orderEdit.squad")}
                    source="squad.id"
                    reference="squads"
                    filter={{
                      competitionId: record.competition.id,
                      orderItemId: record.id,
                      excludeFullSquads: true
                    }}
                    sort={{ field: "id", order: "ASC" }}
                    {...props}
                    helperText={
                      moment(record.squad.startDate)
                        .format("ll")
                        .toString() +
                      " " +
                      moment(record.squad.startTime)
                        .format("LT")
                        .toString() +
                      " - " +
                      moment(record.squad.endTime)
                        .format("LT")
                        .toString()
                    }
                    onChange={(event, value) => chancedSquad(record, value)}
                  >
                    <AutocompleteInput
                      options={{
                        disabled:
                          cancelled ||
                          record.cancelled ||
                          record.archive ||
                          view ||
                          record.registrationEnded
                      }}
                      optionText="name"
                    />
                  </ReferenceInput>,
                  <>
                    {record.epc ? (
                      <p>
                        <img src={"data:image/png;base64," + record.epc} />
                      </p>
                    ) : null}
                    <Chip label={record.paid ? "Bezahlt" : "Nicht Bezahlt"} />
                    {record.cancelled || cancelled ? (
                      <Chip style={{ marginLeft: 10 }} label={"Abgemeldet"} />
                    ) : null}
                  </>
                ]
              ) : (
                <>
                  <Chip label={record.paid ? "Bezahlt" : "Nicht Bezahlt"} />,
                  {record.cancelled || cancelled ? (
                    <Chip style={{ marginLeft: 10 }} label={"Abgemeldet"} />
                  ) : null}
                </>
              )}
              <GenericPopup
                open={cancelOrderItemPopup}
                handleConfirm={() =>
                  cancelOrderItem(record.id).then(
                    success => {
                      setCancelled(true);
                      dispatch(togglePopup("cancelOrderItemPopup"));
                      dispatch(
                        setSuccess({
                          message: "SUCCESS_ORDER_ITEM_CANCEL"
                        })
                      );
                      history.push("/myCompetitions");
                    },
                    e => dispatch(setError(e))
                  )
                }
                handleClose={() =>
                  dispatch(togglePopup("cancelOrderItemPopup"))
                }
                message={translate(
                  "resources.orderList.shooterCancelOrderItemPopup.warning"
                )}
                title={translate(
                  "resources.orderList.shooterCancelOrderItemPopup.title"
                )}
                confirmText={translate(
                  "resources.orderList.shooterCancelOrderItemPopup.confirm"
                )}
                cancelText={translate(
                  "resources.orderList.shooterCancelOrderItemPopup.cancel"
                )}
              />

              {record.registrationEnded && !record.archive && !view ? (
                <div style={{ width: "100%" }}>
                  <Typography
                    variant="display1"
                    style={{
                      backgroundColor: "#ffcc00",
                      padding: "10px",
                      width: "100%",
                      marginTop: "10px"
                    }}
                  >
                    <Warning style={{ marginRight: "10px" }} />
                    {translate(
                      "resources.competitionshow.competitionRegistrationEnded"
                    )}
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{
                      backgroundColor: "#ffcc00",
                      padding: "10px",
                      marginBottom: "10px",
                      width: "100%"
                    }}
                  >
                    {translate(
                      "resources.competitionshow.competitionRegistrationEndedInfo"
                    )}
                  </Typography>
                </div>
              ) : null}
            </SimpleForm>
          )}
        </WithProps>
      </Edit>
    </Grid>
  );
});

const mapStateToProps = state => {
  return {
    cancelOrderItemPopup: state.flags.popupOpen.cancelOrderItemPopup
  };
};

const enhance = compose(
  translate,
  connect(
    mapStateToProps,
    { changeLocale }
  )
);

ShooterOrderItemEdit = reduxForm({
  // a unique name for the form
  form: "shooterOrderItemForm"
})(ShooterOrderItemEdit);

export default enhance(ShooterOrderItemEdit);
