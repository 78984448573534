import React from "react";
import {
  required,
  translate,
  TextInput,
  SimpleForm,
  Edit,
  DisabledInput,
  SelectInput,
  ReferenceInput,
  AutocompleteInput,
  Toolbar,
  SaveButton
} from "react-admin";
import compose from "recompose/compose";
import PageTitle from "../../components/PageTitle";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import Button from "@material-ui/core/Button";
import countries from "./../../components/pages/country-codes-case-lower.json";
import { isAdmin } from "../../utils/utils";
import { getCompetitionSquads } from "../../utils/restUtils";
import { change } from "redux-form";
import { setError } from "../../reducer/errorActions";
import { connect } from "react-redux";

const getCountryChoices = () => {
  return countries.map(country => {
    return { id: country.countryCode, name: country.countryName };
  });
};

const WithProps = ({ children, ...props }) => children(props);

const CustomToolbar = props => {
  const handleClick = () => {
    props.history.push("/orderItemList/");
  };
  return (
    <Toolbar {...props} style={{ justifyContent: "space-between" }}>
      <SaveButton undoable={false} />
      <Button variant="contained" onClick={handleClick}>
        Abbrechen
      </Button>
    </Toolbar>
  );
};

export const OrderItemEdit = compose(
  translate,
  connect(mapStateToProps)
)(({ translate, history, dispatch, cancelOrderItemPopup, state, ...props }) => {
  const permissions = props.permissions;

  const chancedSquad = (record, value) => {
    getCompetitionSquads({
      competitionId: record.competition.id,
      orderItemId: record.id,
      excludeFullSquads: false
    }).then(
      squads => {
        const squad = squads.find(s => s.id === value);
        if (squad) {
          record.squad = squad;
          record.divisionId = record.division.id;
          record.factorId = record.factor.id;
          record.competitionClassId = record.competitionClass.id;

          dispatch(change("shooterOrderItemForm", "squad", squad));
          dispatch(
            change("shooterOrderItemForm", "division.id", record.division.id)
          );
          dispatch(
            change("shooterOrderItemForm", "factor.id", record.factor.id)
          );
          dispatch(
            change(
              "shooterOrderItemForm",
              "competitionClass.id",
              record.competitionClass.id
            )
          );
        }
      },
      e => dispatch(setError(e))
    );
  };

  return (
    <Grid>
      <PageTitle title={translate("resources.orderEdit.headline")} />
      <Edit {...props} undoable={false}>
        <WithProps>
          {({ record, ...props }) => {
            return (
              <SimpleForm
                record={record}
                toolbar={<CustomToolbar history={history} />}
                {...props}
                redirect={"/orderItemList/"}
              >
                <DisabledInput
                  label={translate("resources.orderEdit.orderItemNumber")}
                  source="id"
                />
                <TextInput
                  source="firstName"
                  label={translate("resources.orderEdit.firstName")}
                  validate={required()}
                />
                <TextInput
                  source="lastName"
                  label={translate("resources.orderEdit.lastName")}
                  validate={required()}
                />
                <TextInput
                  source="email"
                  label={translate("resources.orderEdit.email")}
                  validate={required()}
                />
                <DisabledInput
                  label={translate("resources.orderEdit.competition")}
                  source="competition.name"
                  optionText="name"
                />
                {isAdmin(permissions) ? (
                  <ReferenceInput
                    label={translate("resources.orderList.userId")}
                    source="user.id"
                    reference="users"
                    perPage={999}
                    sort={{ field: "lastName", order: "ASC" }}
                    {...props}
                    filter={{ active: true, userRole: "ROLE_USER" }}
                  >
                    <AutocompleteInput optionText="fullname" />
                  </ReferenceInput>
                ) : null}
                {!record.competition.event
                  ? [
                      <ReferenceInput
                        label={translate("resources.orderEdit.divisions")}
                        source="division.id"
                        reference="divisions"
                        perPage={100}
                        sort={{ field: "name", order: "ASC" }}
                        filter={{ competitionId: record.competition.id, squadId: record.squad.id }}
                        {...props}
                      >
                        <AutocompleteInput
                          optionText="name"
                          options={{
                            disabled:
                              record.cancelled ||
                              record.archive
                          }}
                        />
                      </ReferenceInput>,
                      <ReferenceInput
                        label={translate("resources.orderEdit.factor")}
                        source="factor.id"
                        reference="factors"
                        perPage={100}
                        sort={{ field: "name", order: "ASC" }}
                        filter={{ competitionId: record.competition.id, squadId: record.squad.id }}
                        {...props}
                      >
                        <AutocompleteInput
                          optionText="name"
                          options={{
                            disabled:
                              record.cancelled ||
                              record.archive
                          }}
                        />
                      </ReferenceInput>,
                      <ReferenceInput
                        label={translate(
                          "resources.orderEdit.competitionClass"
                        )}
                        source="competitionClass.id"
                        reference="competitionClasses"
                        perPage={100}
                        sort={{ field: "name", order: "ASC" }}
                        filter={{ competitionId: record.competition.id, squadId: record.squad.id }}
                        {...props}
                      >
                        <AutocompleteInput
                          optionText="name"
                          options={{
                            disabled:
                              record.cancelled ||
                              record.archive
                          }}
                        />
                      </ReferenceInput>,
                      <ReferenceInput
                        {...props}
                        label={translate("resources.orderEdit.squad")}
                        source="squad.id"
                        perPage={100}
                        filter={{
                          competitionId: record.competition.id,
                          orderItemId: record.id,
                          excludeFullSquads: true
                        }}
                        reference="squads"
                        onChange={(event, value) => chancedSquad(record, value)}
                      >
                        <AutocompleteInput optionText="name" />
                      </ReferenceInput>,
                      record.squad.stands &&
                      record.squad.stands.length > 0 &&
                      !record.waitingList ? (
                        <SelectInput
                          name="squadStand"
                          source="squadStand.id"
                          validate={required()}
                          choices={record.squad.stands}
                          label={translate(
                            "resources.competitionspub.signupForm.squadStand"
                          )}
                          {...props}
                        />
                      ) : null,
                      isAdmin(permissions) ? (
                        <ReferenceInput
                          {...props}
                          label={translate("resources.orderEdit.club")}
                          source="club.id"
                          perPage={100}
                          sort={{ field: "name", order: "ASC" }}
                          filter={{ active: true }}
                          reference="clubs"
                        >
                          <AutocompleteInput optionText="name" />
                        </ReferenceInput>
                      ) : (
                        <DisabledInput
                          label={translate("resources.orderEdit.club")}
                          source="club.name"
                          optionText="name"
                        />
                      ),
                      <SelectInput
                        source="country"
                        label={translate("resources.orderEdit.country")}
                        choices={getCountryChoices()}
                      />,
                      <Chip
                        style={{ marginTop: "30px" }}
                        label={record.paid ? "Bezahlt" : "Nicht Bezahlt"}
                      />
                    ]
                  : [
                      isAdmin(permissions) ? (
                        <ReferenceInput
                          {...props}
                          label={translate("resources.orderEdit.club")}
                          source="club.id"
                          perPage={100}
                          sort={{ field: "name", order: "ASC" }}
                          filter={{ active: true }}
                          reference="clubs"
                        >
                          <AutocompleteInput optionText="name" />
                        </ReferenceInput>
                      ) : (
                        <DisabledInput
                          label={translate("resources.orderEdit.club")}
                          source="club.name"
                          optionText="name"
                        />
                      ),
                      <SelectInput
                        source="country"
                        choices={getCountryChoices()}
                        label={translate("resources.orderEdit.country")}
                      />,
                      <Chip
                        style={{ marginTop: "30px" }}
                        label={record.paid ? "Bezahlt" : "Nicht Bezahlt"}
                      />
                    ]}
              </SimpleForm>
            );
          }}
        </WithProps>
      </Edit>
    </Grid>
  );
});

const mapStateToProps = state => {
  return {};
};
