import { Button, Grid } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import download from "downloadjs";
import React, { Component } from "react";
import { WithPermissions } from "react-admin";
import { connect } from "react-redux";
import { Field, Form, reduxForm, blur, touch } from "redux-form";
import { setError } from "../../reducer/errorActions";
import { DateInput, DateTimeInput, TimeInput } from "react-admin-date-inputs";
import MomentUtils from "material-ui-pickers/utils/moment-utils";
import Typography from "@material-ui/core/Typography";
import deLocale from "date-fns/locale/de";
import {
  exportAllAdvertisment,
  exportallCompetitors,
  exportallUsers,
  exportFilteredOrderItems,
  exportPractiScoreForCompetition,
  exportPractiScoreWithStandForCompetition,
  getCompetitions,
  getClubs,
  exportAdminCompetitionList,
  getCompetitionFactors,
  getCompetitionClasses,
  getCompetitionDevisions
} from "../../utils/restUtils";
import { isAdmin, isPortalAdmin, isClubAdmin } from "../../utils/utils";
import { renderDateField, renderReactDateField } from "./renderMaterialFields";
import CustomAutocompleteInput from "../CustomAutocompleteInput";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment";

const renderCustomAutocompleteInput = ({
  input,
  label,
  type,
  options,
  source,
  translate,
  validate,
  defaultValue,
  sort,
  isMulti,
  meta: { touched, error, warning, invalid }
}) => (
  <>
    <CustomAutocompleteInput
      validate={validate}
      {...input}
      invalid={invalid}
      placeholder={label}
      source={source}
      type={type}
      isMulti={isMulti}
      options={options}
      translate={translate}
      defaultValue={defaultValue}
      label={renderCustomLabel(translate(label))}
      touched={touched}
      error={error}
      warning={warning}
      sort={sort}
    />
    {touched &&
      ((error && (
        <Typography>
          <span style={{ color: "red", fontSize: "12px" }}>
            {translate(error)}
          </span>
        </Typography>
      )) ||
        (warning && <p>{warning}</p>))}
  </>
);

const required = value => {
  return value && value !== "" && !Array.isArray(value)
    ? undefined
    : "FIELD_IS_REQUIRED";
};

const renderCustomLabel = label => {
  var labelStyle = {
    color: "gray",
    fontSize: "16px"
  };
  return <label style={labelStyle}>{label}</label>;
};

class ExportSelect extends Component {
  state = {
    waiting: false
  };

  constructor(props) {
    super(props);
    this.state = { value: "" };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  createFilename(name) {
    return (
      name +
      "-" +
      new Date().getFullYear() +
      "-" +
      (new Date().getMonth() + 1) +
      "-" +
      new Date().getDate() +
      "_" +
      new Date().getHours() +
      "_" +
      new Date().getMinutes() +
      "_" +
      new Date().getSeconds() +
      ".xlsx"
    );
  }

  createFilenameCsv(name) {
    return (
      name +
      "-" +
      new Date().getFullYear() +
      "-" +
      (new Date().getMonth() + 1) +
      "-" +
      new Date().getDate() +
      "_" +
      new Date().getHours() +
      "_" +
      new Date().getMinutes() +
      "_" +
      new Date().getSeconds() +
      ".csv"
    );
  }

  decorateCompetitions = competitions => {
    for (let i = 0; i < competitions.length; i++) {
      competitions[i].name +=
        " (" + moment(competitions[i].competitionDate).format("L") + ")";
    }
    return competitions;
  };
  componentDidMount = () => {
    getCompetitions(
      0,
      300,
      "competitionDate",
      "desc",
      isClubAdmin
        ? { clubId: this.props.identity.userData.clubId, event: false }
        : { event: false }
    ).then(
      response => {
        this.setState({
          competitions: this.decorateCompetitions(response.data)
        });
      },
      e => {
        this.props.dispatch(setError(e));
      }
    );
    getClubs().then(
      response => {
        this.setState({
          clubs: response
        });
      },
      e => {
        this.props.dispatch(setError(e));
      }
    );
    getCompetitionClasses().then(
      response => {
        this.setState({
          competitionClasses: response
        });
      },
      e => {
        this.props.dispatch(setError(e));
      }
    );
    getCompetitionDevisions().then(
      response => {
        this.setState({
          divisions: response
        });
      },
      e => {
        this.props.dispatch(setError(e));
      }
    );
    getCompetitionFactors().then(
      response => {
        this.setState({
          factors: response
        });
      },
      e => {
        this.props.dispatch(setError(e));
      }
    );
  };

  handleChange(event) {
    if (event.target.value === "competitions") this.props.selectLongMenu(true);
    else this.props.selectLongMenu(false);
    this.setState({ value: event.target.value });
  }

  exportallCompetitorsCall(event) {
    exportFilteredOrderItems(null, "id", "asc")
      .then(response => {
        return response.blob();
      })
      .then(
        data => {
          download(
            data,
            this.createFilename("Bewerbs-Anmeldungen"),
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          );
          this.waitingOff();
        },
        e => {
          this.waitingOff();
          this.props.dispatch(setError(e));
        }
      );
  }

  exportallUsersCall(event) {
    exportallUsers()
      .then(response => {
        return response.blob();
      })
      .then(
        data => {
          this.waitingOff();
          download(
            data,
            this.createFilename("benutzer"),
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          );
        },
        e => {
          this.waitingOff();
          this.props.dispatch(setError(e));
        }
      );
  }

  exportAdvertismentCall(from, to, event) {
    exportAllAdvertisment(from, to)
      .then(response => {
        return response.blob();
      })
      .then(
        data => {
          this.waitingOff();
          download(
            data,
            this.createFilename("Werbungen"),
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          );
        },
        e => {
          this.waitingOff();
          this.props.dispatch(setError(e));
        }
      );
  }

  exportPractiScoreCall(competitionId) {
    exportPractiScoreForCompetition(competitionId)
      .then(response => {
        return response.blob();
      })
      .then(
        data => {
          this.waitingOff();
          download(
            data,
            this.createFilenameCsv("Bewerbs Practi Score"),
            "text/csv"
          );
        },
        e => {
          this.waitingOff();
          this.props.dispatch(setError(e));
        }
      );
  }

  exportPractiScoreStandCall(competitionId) {
    exportPractiScoreWithStandForCompetition(competitionId)
      .then(response => {
        return response.blob();
      })
      .then(
        data => {
          this.waitingOff();
          download(
            data,
            this.createFilenameCsv("Bewerbs Practi Score mit Stand"),
            "text/csv"
          );
        },
        e => {
          this.waitingOff();
          this.props.dispatch(setError(e));
        }
      );
  }

  exportCompetitions(
    from,
    to,
    clubs,
    exportSquads,
    exportOrderItems,
    division,
    competitionClass,
    factor
  ) {
    exportAdminCompetitionList(clubs, {
      from: from,
      to: to,
      exportSquads: exportSquads,
      exportOrderItems: exportOrderItems,
      divison: division,
      competitionClass: competitionClass,
      factor: factor
    })
      .then(response => {
        return response.blob();
      })
      .then(
        data => {
          this.waitingOff();
          download(
            data,
            this.createFilename("Comp|Sign Bewerbe"),
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          );
        },
        e => {
          this.waitingOff();
          this.props.dispatch(setError(e));
        }
      );
  }

  handleSubmit(event) {
    this.waitingOn();
    if (this.state.value == "competitorsGroupedByCompetition")
      this.exportallCompetitorsCall();
    if (this.state.value == "allUsers") this.exportallUsersCall();
    if (this.state.value === "advertisments")
      this.exportAdvertismentCall(
        this.props.myForm && this.props.myForm.fromDate
          ? this.props.myForm.fromDate.format("YYYY-MM-DD HH:mm:ss")
          : null,
        this.props.myForm && this.props.myForm.toDate
          ? this.props.myForm.toDate.format("YYYY-MM-DD HH:mm:ss")
          : null,
        event
      );
    if (this.state.value === "competitionPractiScore") {
      this.props.dispatch(touch("exportForm", "competition"));
      this.props.dispatch(blur("exportForm", "competition"));
      if (
        this.props.myForm &&
        this.props.myForm.competition &&
        this.props.myForm.competition !== "" &&
        !Array.isArray(this.props.myForm.competition)
      )
        this.exportPractiScoreCall(this.props.myForm.competition);
    }
    if (this.state.value === "competitionPractiScoreStand") {
      this.props.dispatch(touch("exportForm", "competition"));
      this.props.dispatch(blur("exportForm", "competition"));
      if (
        this.props.myForm &&
        this.props.myForm.competition &&
        this.props.myForm.competition !== "" &&
        !Array.isArray(this.props.myForm.competition)
      )
        this.exportPractiScoreStandCall(this.props.myForm.competition);
    }
    if (this.state.value === "competitions") {
      this.exportCompetitions(
        this.props.myForm && this.props.myForm.fromDate
          ? this.props.myForm.fromDate.format("YYYY-MM-DD HH:mm:ss")
          : null,
        this.props.myForm && this.props.myForm.toDate
          ? this.props.myForm.toDate.format("YYYY-MM-DD HH:mm:ss")
          : null,
        this.props.myForm && this.props.myForm.clubs
          ? this.props.myForm.clubs
          : [],
        this.props.myForm ? this.props.myForm.exportSquads : null,
        this.props.myForm ? this.props.myForm.exportOrderItems : null,
        this.props.myForm ? this.props.myForm.division : null,
        this.props.myForm ? this.props.myForm.competitionClass : null,
        this.props.myForm ? this.props.myForm.factor : null
      );
    }
    event.preventDefault();
  }

  waitingOn = () => {
    this.setState({ waiting: true });
  };

  waitingOff = () => {
    this.setState({ waiting: false });
  };

  render() {
    return (
      <div>
        <InputLabel>
          {this.props.translate("resources.exports.types.title")}
        </InputLabel>
        <br />
        <WithPermissions
          render={({ permissions }) => (
            <Select
              value={this.state.value}
              onChange={this.handleChange}
              fullWidth
            >
              <MenuItem value=""></MenuItem>
              <MenuItem value="competitorsGroupedByCompetition">
                {this.props.translate(
                  "resources.exports.types.competitorsGroupedByCompetition"
                )}
              </MenuItem>
              <MenuItem value="competitionPractiScore">
                {this.props.translate(
                  "resources.exports.types.competitionPractiScore"
                )}
              </MenuItem>
              <MenuItem value="competitionPractiScoreStand">
                {this.props.translate(
                  "resources.exports.types.competitionPractiScoreStand"
                )}
              </MenuItem>
              {isAdmin(permissions) || isPortalAdmin(permissions) ? (
                <MenuItem value="allUsers">
                  {this.props.translate("resources.exports.types.allUsers")}
                </MenuItem>
              ) : null}
              {isAdmin(permissions) || isPortalAdmin(permissions) ? (
                <MenuItem value="advertisments">
                  {this.props.translate(
                    "resources.exports.types.advertisments"
                  )}
                </MenuItem>
              ) : null}
              {isAdmin(permissions) || isPortalAdmin(permissions) ? (
                <MenuItem value="competitions">
                  {this.props.translate("resources.exports.types.competitions")}
                </MenuItem>
              ) : null}
            </Select>
          )}
        />
        {this.state && this.state.value === "advertisments" ? (
          <Grid>
            <Form>
              <Field
                name="fromDate"
                component={renderReactDateField}
                label="Von"
                defaultValue=""
                floatingLabelFixed
              />
              <Field
                name="toDate"
                component={renderReactDateField}
                label="Bis"
                defaultValue=""
                floatingLabelFixed
              />
            </Form>
          </Grid>
        ) : null}
        {this.state &&
        (this.state.value === "competitionPractiScore" ||
          this.state.value === "competitionPractiScoreStand") ? (
          <Grid>
            <Form>
              <Field
                name="competition"
                validate={[required]}
                options={this.state.competitions}
                component={renderCustomAutocompleteInput}
                label="resources.exports.competition"
                translate={this.props.translate}
              />
            </Form>
          </Grid>
        ) : null}
        {this.state && this.state.value === "competitions" ? (
          <Grid>
            <Form>
              <Field
                name="fromDate"
                component={renderReactDateField}
                label="Von"
                defaultValue=""
                floatingLabelFixed
              />
              <Field
                name="toDate"
                component={renderReactDateField}
                label="Bis"
                defaultValue=""
                floatingLabelFixed
              />
              <Field
                name="clubs"
                options={this.state.clubs}
                isMulti={true}
                component={renderCustomAutocompleteInput}
                label="resources.exports.clubs"
                translate={this.props.translate}
              />
              <Field
                label="resources.exports.exportSquads"
                component="input"
                name="exportSquads"
                type="checkbox"
                translate={this.props.translate}
              />
              {renderCustomLabel(
                this.props.translate("resources.exports.exportSquads")
              )}
              <br />
              <Field
                label="resources.exports.exportOrderItems"
                component="input"
                name="exportOrderItems"
                type="checkbox"
                translate={this.props.translate}
              />
              {renderCustomLabel(
                this.props.translate("resources.exports.exportOrderItems")
              )}
              <Field
                name="division"
                options={this.state.divisions}
                component={renderCustomAutocompleteInput}
                label="resources.exports.division"
                translate={this.props.translate}
              />
              <Field
                name="competitionClass"
                options={this.state.competitionClasses}
                component={renderCustomAutocompleteInput}
                label="resources.exports.competitionClass"
                translate={this.props.translate}
              />
              <Field
                name="factor"
                options={this.state.factors}
                component={renderCustomAutocompleteInput}
                label="resources.exports.factor"
                translate={this.props.translate}
              />
            </Form>
          </Grid>
        ) : null}
        <br />
        {this.state.value ? (
          <Button
            disabled={this.state.waiting}
            variant="contained"
            color="primary"
            onClick={this.handleSubmit}
            style={{ marginTop: "20px", backgroundColor: "#7e886e" }}
          >
            {this.props.translate("resources.exports.submit")}
          </Button>
        ) : null}
        <div style={{ marginTop: "20px", float: "right" }}>
          {this.state.waiting && <CircularProgress size={25} thickness={2} />}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  myForm:
    state.form["exportForm"] && state.form["exportForm"].values
      ? state.form["exportForm"].values
      : null,
  registeredFields:
    state.form["exportForm"] && state.form["exportForm"].registeredFields
      ? state.form["exportForm"].registeredFields
      : null,
  identity: state.identity
});

export default connect(mapStateToProps)(
  reduxForm({
    // a unique name for the form
    form: "exportForm"
  })(ExportSelect)
);
