import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { translate } from "react-admin";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import { change, Field, Form, reduxForm } from "redux-form";
import { renderTextField } from "./inputs/renderMaterialFields";
import { required } from "./inputs/validator";
import restClient from "../providers/dataProvider/rest";
import { IconButton } from "@material-ui/core";
import CopyIcon from "@material-ui/icons/Dashboard";
import moment from "moment";

const styles = {
  errorMsg: {
    color: "#CB5D58",
    marginTop: "10px"
  },
  msg: {
    marginTop: "10px"
  },
  dialogWrapper: {
    minWidth: "400px",
    paddingTop: "0px",
    paddingBottom: "5px"
  }
};

let CompetitionDetailsPopup = props => {
  const { handleClose, open, errorMessage, fullScreen, competition } = props;

  const comp = competition || { name: "Not loaded" };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{comp.name}</DialogTitle>
      <DialogContent style={styles.dialogWrapper}>
        <div>
          {comp.createdAt ? (
            <div>
              <span>Erstellt am: </span>
              <span>
                {moment(comp.createdAt)
                  .format("ddd DD.MM.YYYY HH:mm:ss")
                  .toString()}
              </span>
            </div>
          ) : null}
          {comp.paidAt ? (
            <div>
              <span>Bezahlt am: </span>
              <span>
                {moment(comp.paidAt)
                  .format("ddd DD.MM.YYYY HH:mm:ss")
                  .toString()}
              </span>
            </div>
          ) : null}
          {comp.cancelledAt ? (
            <div>
              <span>Abgesagt am: </span>
              <span>
                {moment(comp.cancelledAt)
                  .format("ddd DD.MM.YYYY HH:mm:ss")
                  .toString()}
              </span>
            </div>
          ) : null}
          {comp.deletedAt ? (
            <div>
              <span>Gelöscht am: </span>
              <span>
                {moment(comp.deletedAt)
                  .format("ddd DD.MM.YYYY HH:mm:ss")
                  .toString()}
              </span>
            </div>
          ) : null}
          <div>
            <span>Anmeldungen: </span>
            <span>{comp.orderItemCount}</span>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} style={{ color: "#4F6982" }}>
          Schließen
        </Button>
      </DialogActions>
    </Dialog>
  );
};

CompetitionDetailsPopup = reduxForm({
  // a unique name for the form
  form: "competitionDetailsForm"
})(CompetitionDetailsPopup);

function mapStateToProps(state) {
  return {
    myForm: state.form.competitionInvoiceForm
  };
}

CompetitionDetailsPopup.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
  competition: PropTypes.object
};

export default translate(
  connect(mapStateToProps)(withMobileDialog()(CompetitionDetailsPopup))
);
